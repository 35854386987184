<template>
   <v-card-title ui-test-data="dialog-header">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn v-if="newItemRoute" class="green--text" :href="newItemRoute" target="_blank" icon>
         <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-if="clickReload" class="blue--text" icon @click="clickReload">
         <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn v-shortkey.once="['esc']" class="red--text ml-1" icon @shortkey="clickClose" @click="clickClose">
         <v-icon>mdi-close</v-icon>
      </v-btn>
      <slot></slot>
   </v-card-title>
</template>
<script lang="ts">
import { RoutePathWithoutParams } from "@root/routes";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
   name: "AddReferenceDialogHeader",
   components: {},
})
export default class AddReferenceDialogHeader extends Vue {
   @Prop({ default: "Title missing" })
   title: string;

   @Prop({ default: null })
   newItemRoute: RoutePathWithoutParams;

   @Prop({ default: null })
   clickReload: () => Promise<void>;

   @Prop({ default: null })
   clickClose: () => void;
}
</script>
