export enum RouteNavValue {
   Dashbord,
   Projects,
   ProjectsDraft,
   DomainDataModels,
   ContentBricks,
   DesignGuideline,
   PBB,
   PbbQueries,
   GeneralLibrary,
   Administration,
   Tasks,
   Capabilities,
   Domains,
   Lists,
   PublicHolidays,
   Qualifications,
   Reports,
   TagsAndSearchIdentifiers,
   Translations,
   UnitTypes,
   Units,
   UsageTypes,
   Groups,
   Organigram,
   Roles,
   Users,
   DomainDataModelInstances,
   RegularExpressions,
   SubContentBricks,
   PracticalTests,
   AutomaticImports,
   EnactmentPriorityLabels,
   GeneralTests,
   NormsAndStandards,
   MaterialCompliances,
   PreShipmentInstructions,
   TechnicalMeasures,
}
