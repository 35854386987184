import moment from "moment";
import DataModelUtils from "@utils/DataModelUtils";

export type ValidationRule<Tinput = string | null | undefined> = (x: Tinput) => boolean | string;

const validationRules = (function () {
   function isEmpty(input: string): boolean {
      return !input;
   }

   const isoFormatTime: string = "HH:mm:ss";
   const isoFormatDate: string = "YYYY-MM-DD";
   const isoFormatDateTime: string = "YYYY-MM-DDTHH:mm:ss";
   const isoFormatDateTimeFractions: string[] = [
      isoFormatDateTime,
      "YYYY-MM-DDTHH:mm:ss.SZ",
      "YYYY-MM-DDTHH:mm:ss.SSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ",
      "YYYY-MM-DDTHH:mm:ss.S",
      "YYYY-MM-DDTHH:mm:ss.SS",
      "YYYY-MM-DDTHH:mm:ss.SSS",
      "YYYY-MM-DDTHH:mm:ss.SSSS",
      "YYYY-MM-DDTHH:mm:ss.SSSSS",
      "YYYY-MM-DDTHH:mm:ss.SSSSSS",
      "YYYY-MM-DDTHH:mm:ss.SSSSSSS",
   ];
   const identifierRegex: RegExp = /^([^\W\d_A-Z]){1}([^\W_]*)$/;
   const uniqueIdentificator: RegExp = /^[A-Z](?:[A-Z0-9]*-?[A-Z0-9]+)*$/;

   function identifierRegexTest(v): string | boolean {
      return (
         identifierRegex.test(v) ||
         "Identifier must be at least 1 character long containing only 0-9, a-z, A-Z characters, starting with a lower-case letter"
      );
   }

   function uniqueIdentificatorRegexTest(v): string | boolean {
      return (
         uniqueIdentificator.test(v) ||
         "Identificator must contain only A-Z, 0-9 characters, separated by -, starting with a letter"
      );
   }

   return {
      isoFormatTime: isoFormatTime,
      isoFormatDate: isoFormatDate,
      isoFormatDateTime: isoFormatDateTime,
      isoFormatDateTimeFractions: isoFormatDateTimeFractions,
      isoTime: ([] = [
         (v: string) => isEmpty(v) || moment(v, isoFormatTime, true).isValid() || "Incorrect format of time",
      ]),
      isoDate: ([] = [
         (v: string) => isEmpty(v) || moment(v, isoFormatDate, true).isValid() || "Incorrect format of date",
      ]),
      isoDateTime: ([] = [
         (v: string) => isEmpty(v) || moment(v, isoFormatDateTime, true).isValid() || "Incorrect format of date time",
      ]),
      identifier: ([] = [(v: string) => !!v?.trim() || "Identifier is required", identifierRegexTest]),
      identifierWithoutTrim: ([] = [identifierRegexTest]),
      uniqueIdentificator: ([] = [uniqueIdentificatorRegexTest]),
      nameAsIdentifier: ([] = [
         (v: string | null | undefined) =>
            identifierRegex.test(DataModelUtils.generateNodeIdentifier(v ?? undefined) ?? "") ||
            "Name must be at least 1 character long (skipping non-alphanumeric characters)",
      ]),
      rulesWithFormat: function <T>(rules: ValidationRule<T>[], format: string): ValidationRule<T>[] {
         return rules.map((rule) => (v: T) => {
            const validationResult = rule(v);
            if (validationResult !== true) {
               return `${validationResult} (${format})`; // extend message with format in validation error text
            }

            return true;
         });
      },
   };
})();

export default validationRules;
