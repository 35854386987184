<template>
   <div class="vqb-rule card">
      <div v-if="query.hasPermission !== false" class="form-inline align-baseline">
         <h6 v-if="!rule.isError" class="mr-5 mb-0">{{ rule.label }}</h6>
         <h6 v-else class="mr-5 mb-0 red--text">Error: field ID '{{ rule.id }}' not found</h6>

         <!-- List of operands (optional) -->
         <v-select
            v-if="typeof rule.operands !== 'undefined'"
            v-model="query.operand"
            :items="rule.operands"
            :readonly="readonly"
            class="mt-1 mr-2 flex-grow-0"
            hide-details
            dense
            outlined
         />

         <!-- List of operators (e.g. =, !=, >, <) -->
         <v-select
            v-if="typeof rule.operators !== 'undefined' && rule.operators.length > 1"
            :items="rule.operators"
            :value="query.operator"
            :readonly="readonly"
            class="mt-1 mr-2 flex-grow-0"
            hide-details
            dense
            outlined
            label="Operator"
            @change="onOperatorChanged"
         />

         <div v-if="hasOperatorSecondSideArg" class="d-flex my-1 flex-grow-0" :class="{ 'v-hidden': rule.isError }">
            <!-- Toggle literal/field reference/query -->
            <v-btn-toggle
               v-if="!rule.cantChangeValueType"
               v-model="valueType"
               label="Value type"
               class="btn-color-disabled mt-1 mr-3"
               dense
               mandatory
               @change="resetRightValue"
            >
               <v-btn value="literal" :disabled="readonly">Value</v-btn>
               <v-btn value="reference" :disabled="readonly">{{ referenceLabel }}</v-btn>
               <v-btn v-if="!rule.isGateCondition" value="fieldReferenceQuery" :disabled="readonly">Field query</v-btn>
            </v-btn-toggle>
         </div>
         <div v-if="hasOperatorSecondSideArg" class="d-flex my-1 flex-grow-1" :class="{ 'v-hidden': rule.isError }">
            <div v-if="valueType === 'literal'">
               <!-- Basic text input -->
               <v-text-field
                  v-if="rule.inputType === 'text'"
                  v-model="query.value"
                  label="Value"
                  :readonly="readonly"
                  type="text"
                  :rules="basicFieldRules"
                  class="mr-auto flex-grow-0"
                  :placeholder="labels.textInputPlaceholder"
                  outlined
                  dense
                  hide-details="auto"
               ></v-text-field>
               <!-- Basic number input -->
               <v-text-field
                  v-if="rule.inputType === 'number'"
                  v-model="query.value"
                  :readonly="readonly"
                  label="Value"
                  class="mr-auto flex-grow-0"
                  type="number"
                  :rules="numericValueRules"
                  outlined
                  dense
                  hide-details="auto"
               ></v-text-field>
               <!-- Basic boolean input -->
               <v-select
                  v-if="rule.type === 'boolean'"
                  v-model="query.value"
                  :items="booleanValues"
                  label="Value"
                  item-text="label"
                  item-value="value"
                  :readonly="readonly"
                  :rules="booleanValueRules"
                  class="mr-2 flex-grow-0"
                  dense
                  outlined
                  hide-details="auto"
               ></v-select>
               <!-- Date, dateTime, time -->
               <date-time-picker
                  v-if="rule.type === 'date' || rule.type === 'time' || rule.type === 'dateTime'"
                  v-model="query.value"
                  :readonly="readonly"
                  class="mr-auto flex-grow-0 mt-0"
                  clearable
                  :date="rule.type === 'date' || rule.type === 'dateTime'"
                  :time="rule.type === 'time' || rule.type === 'dateTime'"
                  :rules="dateValueRules(rule)"
               ></date-time-picker>
               <!-- Custom component input -->
               <div v-if="isCustomComponent" class="vqb-custom-component-wrap">
                  <component :is="rule.component" :value="query.value" @input="updateQuery" />
               </div>
               <!-- Checkbox input -->
               <template v-if="rule.inputType === 'checkbox'">
                  <div v-for="choice in rule.choices" :key="choice.value" class="form-check form-check-inline">
                     <input
                        :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                        v-model="query.value"
                        type="checkbox"
                        :value="choice.value"
                        class="form-check-input"
                     />
                     <label
                        class="form-check-label"
                        :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                     >
                        {{ choice.label }}
                     </label>
                  </div>
               </template>
               <!-- Radio input -->
               <template v-if="rule.inputType === 'radio'">
                  <div v-for="choice in rule.choices" :key="choice.value" class="form-check form-check-inline">
                     <input
                        :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                        v-model="query.value"
                        :name="'depth' + depth + '-' + rule.id + '-' + index"
                        type="radio"
                        :value="choice.value"
                        class="form-check-input"
                     />
                     <label
                        class="form-check-label"
                        :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                     >
                        {{ choice.label }}
                     </label>
                  </div>
               </template>
               <!-- Select without groups -->
               <v-select
                  v-if="rule.inputType === 'select'"
                  v-model="query.value"
                  :items="rule.choices"
                  item-text="label"
                  item-value="value"
                  :readonly="readonly"
                  class="mr-2 flex-grow-0"
                  hide-details
                  dense
                  outlined
                  label="Value"
               ></v-select>
               <v-select
                  v-if="rule.type === 'list'"
                  v-model="query.value"
                  :items="rule.choices"
                  item-text="label"
                  item-value="value"
                  :readonly="readonly"
                  class="mr-2 flex-grow-0"
                  hide-details
                  dense
                  label="Value"
                  outlined
               >
                  <template #item="{ item, on, attrs }">
                     <v-list-item v-bind="attrs" :class="{ 'list-item--hidden': item.disabled }" v-on="on">
                        {{ item.label }}
                     </v-list-item>
                  </template>
               </v-select>
            </div>
            <!-- Reference fields of same type -->
            <v-select
               v-else-if="valueType === 'reference'"
               v-model="query.value"
               :items="rule.fields"
               :label="referenceLabel"
               item-text="label"
               :item-value="(item) => 'ref|' + item.id"
               :readonly="readonly"
               :rules="fieldRules"
               class="rule-class mr-2 mb-0 flex-grow-0"
               hide-details="auto"
               dense
               outlined
            />

            <!-- Reference another field by PDM/DDM query -->
            <div v-else-if="valueType === 'fieldReferenceQuery'" class="d-flex flex-grow-1">
               <v-select
                  v-model="fieldReferenceQueryModel"
                  :items="modelTypes"
                  :readonly="readonly"
                  class="mr-2 flex-grow-0 model-select"
                  hide-details
                  dense
                  outlined
                  label="Model"
               />
               <v-text-field
                  v-model="fieldReferenceQueryValue"
                  label="Field query"
                  :readonly="readonly"
                  type="text"
                  :rules="textValueRules"
                  class="mr-auto flex-grow-1"
                  outlined
                  dense
                  hide-details="auto"
               />
            </div>

            <span v-else>Unsupported value type</span>
         </div>
         <!-- Remove rule button -->
         <div v-if="!readonly" class="align-self-center">
            <v-btn class="ml-3" icon elevation="2" @click="remove">
               <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
         </div>
      </div>
      <span v-else>Insufficient Permissions/Usage Types to view this condition</span>
   </div>
</template>

<script lang="ts">
import QueryBuilderRule from "vue-query-builder/dist/rule/QueryBuilderRule.umd.js";
import ValidationRules from "@models/shared/ValidationRules";
import DateTimePicker from "@components/ContentBricks/Shared/field-date-time-picker.vue";

export default {
   components: {
      DateTimePicker,
   },
   extends: QueryBuilderRule,
   props: ["readonly"],
   data: function () {
      return {
         valueType: this.query.value?.startsWith("ref|")
            ? "reference"
            : this.query.value?.startsWith("fieldRefQuery|")
              ? "fieldReferenceQuery"
              : "literal",
         hasFieldError: false,
         value: ["", "ref|", "fieldRefQuery|"],
         modelTypes: ["DDM", "PDM"],
         booleanValues: [
            { label: "True", value: "true" },
            { label: "False", value: "false" },
            { label: "Undefined", value: "null" },
         ],
         fieldRules: [(v) => this.rule.fields.some((f) => "ref|" + f.id === v) || this.readonly || "Field is required"],
         numericValueRules: [(v) => (!!v && !isNaN(v)) || this.readonly || "Enter a number"],
         textValueRules: [(v) => !!v || this.readonly || "Value is required"],
         booleanValueRules: [
            (v) => this.booleanValues.some((bv) => bv.value === v) || this.readonly || "Value is required",
         ],
         dateMenu: false,
         timeMenu: false,
      };
   },
   computed: {
      fieldReferenceQueryValue: {
         get: function (): string | undefined {
            if (!this.query?.value) {
               return undefined;
            }
            return this.query.value.substring("fieldRefQuery|***|".length, this.query.value.length);
         },
         set: function (value) {
            value && this.query
               ? (this.query.value = `fieldRefQuery|${this.fieldReferenceQueryModel}|${value}`)
               : undefined;
         },
      },
      fieldReferenceQueryModel: {
         get: function (): string | undefined {
            if (!this.query?.value) {
               return "DDM"; /*handle default-initialization*/
            }

            return this.query.value.substring("fieldRefQuery|".length, "fieldRefQuery|***".length);
         },
         set: function (value) {
            if (value && this.query) {
               this.query.value = `fieldRefQuery|${value}|${this.fieldReferenceQueryValue}`;
            }
         },
      },
      hasOperatorSecondSideArg: {
         get: function (): boolean {
            return this.query.operator !== "is empty" && this.query.operator !== "is not empty";
         },
      },
      oldFieldValueType: {
         get: function (): string {
            return this.query.value?.startsWith("ref|")
               ? "reference"
               : this.query.value?.startsWith("fieldRefQuery|")
                 ? "fieldReferenceQuery"
                 : "literal";
         },
      },
      referenceLabel: {
         get: function (): string {
            return this.rule.isGateCondition ? "Query" : "Field";
         },
      },
      basicFieldRules: {
         get: function (): any[] {
            return this.rule.isGateCondition && this.query.operator?.length <= 2
               ? this.numericValueRules
               : this.textValueRules;
         },
      },
   },
   watch: {
      rule: function (newRule, oldRule) {
         if (newRule.type !== oldRule.type) {
            this.query.value = "";
         }
      },
   },
   methods: {
      resetRightValue() {
         if (this.oldFieldValueType === "literal") {
            this.value[0] = this.query.value;
         } else if (this.oldFieldValueType === "reference") {
            this.value[1] = this.query.value;
         } else if (this.oldFieldValueType === "fieldReferenceQuery") {
            this.value[2] = this.query.value;
         }

         this.query.value =
            this.valueType === "literal"
               ? this.value[0]
               : this.valueType === "reference"
                 ? this.value[1]
                 : this.value[2];
      },
      onOperatorChanged(newValue: any) {
         // if is Gate condition, and is literal and changes from text-type operator to numeric-type operator, reset value
         if (
            this.rule.isGateCondition &&
            this.valueType === "literal" &&
            this.query.operator.length > 2 &&
            newValue.length <= 2
         ) {
            this.query.value = "";
         }

         this.query.operator = newValue;
      },
      dateValueRules(type) {
         return [(v) => !!v || this.readonly || "Value is required"];
      },
   },
};
</script>
<style lang="scss">
.v-input.rule-class.error--text.v-text-field.v-text-field--enclosed > .v-input__control .v-text-field__details {
   margin-bottom: 0px !important;
}

.v-select.model-select {
   width: min-content;
   min-width: 95px;

   & .v-select__selection--comma {
      text-overflow: unset;
   }
}
</style>
