import EventBus from "@backend/EventBus";
import GlobalStore from "@backend/store/globalStore";
import Events from "@models/shared/Events";

export default {
   // Set selected domain from a list of domains.
   // It is done only only if selected domain is not in provided list of domains
   // It is selected first available domain from the list
   setDomainFromList(domains: number[] | undefined) {
      const selectedDomain = GlobalStore.getDomain();
      if (domains && domains.length > 0) {
         if (selectedDomain === undefined || !domains.includes(selectedDomain)) {
            // Switch domain only if selectedDomain is undefined or model domains do not include currently selected domain
            GlobalStore.setDomain(domains[0]);
            EventBus.$emit(Events.ChangeDomain);
         }
      }
   },
};
