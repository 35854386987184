<template>
   <v-card v-if="field && value" class="pmtool-card-sub-content-brick" outlined>
      <v-card-text class="text-left">
         {{ fieldLabel }}
         <v-row v-for="scbField in value.$metadata.fields" :key="scbField.id" dense>
            <v-col>
               <v-tooltip v-if="isNumericFieldType(scbField.definition.type)" :disabled="!showTooltip(scbField)" top>
                  <template #activator="{ on }">
                     <v-text-field
                        :type="hasRegularExpression(scbField) && !isFieldFocused(scbField) ? 'text' : 'number'"
                        :label="getFieldLabel(scbField)"
                        :value="getFieldValue(scbField)"
                        :step="scbField.definition.type === ContentBrickFieldType.Decimal ? '0.01' : '1'"
                        :readonly="readonly"
                        :color="color"
                        :rules="getRules(scbField)"
                        v-on="on"
                        @input="updateSubFieldValue(scbField, $event)"
                        @focus="fieldFocusChanged(scbField, true)"
                        @blur="fieldFocusChanged(scbField, false)"
                     ></v-text-field>
                  </template>
                  <span>{{ getTooltip(scbField) }}</span>
               </v-tooltip>
            </v-col>
            <v-col
               v-if="isUnitShown(scbField)"
               cols="auto"
               align-self="center"
               style="font-size: 16px"
               class="pl-0 mb-3 d-flex align-items-end"
            >
               <v-tooltip top>
                  <template #activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">{{ getSubCbFieldUnit(scbField).abbreviation }}</span>
                  </template>
                  <span>{{ getSubCbFieldUnit(scbField).name }}</span>
               </v-tooltip>
               <slot name="append-unit" :scbField="scbField"></slot>
            </v-col>
         </v-row>
      </v-card-text>
   </v-card>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {
   ContentBrickFieldType,
   SubContentBrickFieldDefinition,
   IUnitReference,
   IRegularExpression,
   ContentBrickFieldDefinition,
} from "@backend/api/pmToolApi";
import Spinner from "@components/Shared/spinner.vue";
import DateTimePicker from "@components/ContentBricks/Shared/field-date-time-picker.vue";
import ThreeStateSwitch from "@components/Shared/three-state-switch.vue";
import DataModelFieldUtils from "@utils/DataModelFieldUtils";
import { cloneDeep } from "lodash";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   name: "ScbFieldValue",
   components: {
      Spinner,
      DateTimePicker,
      ThreeStateSwitch,
   },
})
export default class ScbFieldValue extends Vue {
   @Prop({ required: true, default: null })
   value: any | null;

   @Prop({ required: true, default: null })
   field: any | null;

   @Prop({ required: true, default: () => [] })
   units: IUnitReference[];

   @Prop({ default: true })
   showUnits: boolean;

   @Prop({ required: true })
   fieldLabel: string;

   @Prop({ required: true })
   requiredRules: ValidationRule<any>[];

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: undefined })
   color: string | undefined;

   @Prop({ required: true, default: null })
   focusedField: ContentBrickFieldDefinition | SubContentBrickFieldDefinition | null;

   @Prop({ required: true, default: () => [] })
   regularExpressions: IRegularExpression[];

   ContentBrickFieldType: any = ContentBrickFieldType;

   getRules(scbField: SubContentBrickFieldDefinition): ValidationRule<any>[] {
      let rules: ValidationRule<any>[] = this.requiredRules.slice();

      // Add regex rules if subContentBrick field has regularExpression
      if (scbField?.identifier && this.hasRegularExpression(scbField)) {
         const value = this.value[scbField.identifier] ?? null;
         rules.push((v) => !v || this.validateFieldInput(scbField, value));
      }
      return rules;
   }

   getFieldLabel(scbField: SubContentBrickFieldDefinition): string | null {
      return `${scbField.name ?? "Untitled field"} ${scbField.definition.isMandatory ? "*" : ""}`;
   }

   getSubCbFieldUnit(scbField: SubContentBrickFieldDefinition): IUnitReference | undefined {
      if (!this.units?.length) return;
      let unitId = scbField.definition.unitId;
      return unitId ? this.units.find((u) => u.id === unitId) : undefined;
   }

   isUnitShown(scbField: SubContentBrickFieldDefinition): boolean {
      if (!this.showUnits) return false;

      let unit = this.getSubCbFieldUnit(scbField);
      return unit?.isHidden === false;
   }

   getFieldValue(scbField: SubContentBrickFieldDefinition): any {
      if (!this.value) {
         return null;
      }
      if (!scbField.identifier) {
         throw `Identifier of field ${scbField.name} is required.`;
      }
      let value = this.value[scbField.identifier] ?? null;

      return !this.isFieldFocused(scbField) && this.hasRegularExpression(scbField)
         ? (this.getFormatedValueByRegex(value, scbField) ?? null)
         : value;
   }

   updateSubFieldValue(scbField: SubContentBrickFieldDefinition, fieldValue: any) {
      if (!scbField.identifier) {
         throw `Identifier of field ${scbField.name} is required.`;
      }

      let updatedValue = cloneDeep(this.value ?? {});
      this.$set(updatedValue, scbField.identifier, fieldValue);

      this.$emit("input", updatedValue);
   }

   isNumericFieldType(type: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.Integer || type === ContentBrickFieldType.Decimal;
   }

   getTooltip(scbField: SubContentBrickFieldDefinition): string | undefined | null {
      let displayValue: string | undefined | null = undefined;
      let type = scbField.definition.type;

      if (this.isNumericFieldType(type)) {
         displayValue = this.getFieldValue(scbField)?.toString();
      }

      return displayValue;
   }

   showTooltip(scbField: SubContentBrickFieldDefinition): boolean {
      return (this.getTooltip(scbField)?.length ?? 0) > DataModelFieldUtils.tooltipThreshold;
   }

   // ------ Regular Expression ------
   hasRegularExpression(scbField: SubContentBrickFieldDefinition): boolean {
      return RegularExpressionUtils.isRegularExpressionEnabled(scbField.definition.type) &&
         this.getSubCbFieldRegularExpression(scbField)
         ? true
         : false;
   }

   fieldFocusChanged(scbField: SubContentBrickFieldDefinition, value: boolean) {
      this.$emit("fieldFocusChanged", scbField, value);
   }

   isFieldFocused(field: ContentBrickFieldDefinition | SubContentBrickFieldDefinition | undefined): boolean {
      return field && this.focusedField && this.focusedField.id === field.id ? true : false;
   }

   getSubCbFieldRegularExpression(scbField: SubContentBrickFieldDefinition): IRegularExpression | undefined {
      if (scbField?.definition?.regularExpressionId && this.regularExpressions?.length) {
         let regularExpressionId = scbField.definition.regularExpressionId;
         return regularExpressionId ? this.regularExpressions.find((r) => r?.id === regularExpressionId) : undefined;
      } else {
         return undefined;
      }
   }

   getFormatedValueByRegex(value: any | null, scbField: SubContentBrickFieldDefinition): string | undefined {
      if (this.hasRegularExpression(scbField) && value) {
         const regularExpression = this.getSubCbFieldRegularExpression(scbField);
         if (regularExpression?.formatRegexes?.length) {
            let formattedValue: string | undefined = value ? value.toString() : undefined;

            formattedValue = RegularExpressionUtils.formatByRegexProperties(
               formattedValue,
               regularExpression.validationRegex,
               regularExpression.formatRegexes
            );
            if (formattedValue !== undefined) {
               return formattedValue;
            }
         }
      }
      return value !== null ? value : undefined;
   }

   validateFieldInput(scbField: SubContentBrickFieldDefinition, value: any): boolean | string {
      if (!this.hasRegularExpression(scbField)) return true;
      if (!value || value.length == 0) return true;

      const regularExpression = this.getSubCbFieldRegularExpression(scbField);
      if (!regularExpression) {
         return "regularExpression not found";
      }

      if (
         RegularExpressionUtils.validate(
            value.toString(),
            regularExpression.validationRegex?.regex,
            regularExpression.validationRegex?.flags
         )
      ) {
         // regex Validation successfull
         return true;
      } else {
         // regex validation failed
         return RegularExpressionUtils.regexNotMatchMessage;
      }
   }
}
</script>
<style scoped>
.v-card.pmtool-card-sub-content-brick {
   background-color: inherit !important; /*inherit parent card color*/
}
</style>
