<template>
   <v-dialog v-model="showDialog" width="75%" persistent scrollable>
      <v-card>
         <v-card-title primary-title>
            <span>{{ title }}</span>
            <v-spacer />
            <v-btn class="blue--text" icon href="/contentBricks/overview" target="_blank">
               <v-icon>mdi-cube</v-icon>
            </v-btn>
            <v-btn class="blue--text" icon href="/domainDataModels/overview" target="_blank">
               <v-icon>mdi-database-outline</v-icon>
            </v-btn>
            <v-btn v-shortkey.once="['esc']" class="red--text" icon @shortkey="clickClose" @click="clickClose">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-row v-if="result && result.error">
               <v-col>
                  <span>
                     <v-icon color="warning">mdi-exclamation-thick</v-icon>
                     {{ result.error }}
                  </span>
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <h6>
                     <v-row justify="start">
                        <v-col cols="auto" class="pt-0 pb-0" align-self="center">
                           <span>Queries</span>
                        </v-col>
                        <v-col cols="auto" class="pt-0 pb-0" align-self="center">
                           <v-menu open-on-hover offset-y>
                              <template #activator="{ on, attrs }">
                                 <v-btn v-bind="attrs" elevation="2" :disabled="readonly" v-on="on">
                                    <v-icon color="success">mdi-plus</v-icon>
                                    <span>Add</span>
                                 </v-btn>
                              </template>
                              <v-list>
                                 <v-list-item>
                                    <v-btn text block @click="onAddQueryClick(ProjectDataModelType.DomainDataModel)">
                                       <v-icon color="success">mdi-database-outline</v-icon>
                                       <span>Domain Data Model</span>
                                    </v-btn>
                                 </v-list-item>
                                 <v-list-item>
                                    <v-btn text block @click="onAddQueryClick(ProjectDataModelType.ProcessDataModel)">
                                       <v-icon color="success">mdi-cog-outline</v-icon>
                                       <span>Process Data Model</span>
                                    </v-btn>
                                 </v-list-item>
                              </v-list>
                           </v-menu>
                        </v-col>
                     </v-row>
                  </h6>
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <v-data-table
                     class="pmtool-table-overview query-table"
                     :mobile-breakpoint="0"
                     dense
                     :headers="headerQueries"
                     :items="queries"
                     hide-default-footer
                     disable-pagination
                     no-data-text="No queries defined. You can add queries via the 'Add' button"
                  >
                     <template #item.name="{ item }">
                        <v-text-field
                           v-model="item.name"
                           :rules="nameRules"
                           :readonly="readonly"
                           maxlength="80"
                        ></v-text-field>
                     </template>
                     <template #item.dataModelType="{ item }">
                        <v-tooltip v-if="item.dataModelType === ProjectDataModelType.DomainDataModel" top>
                           <template #activator="{ on, attrs }">
                              <v-icon class="grey--text mt-4" v-on="on">mdi-database-outline</v-icon>
                           </template>
                           <span>Domain Data Model</span>
                        </v-tooltip>
                        <v-tooltip v-else-if="item.dataModelType === ProjectDataModelType.ProcessDataModel" top>
                           <template #activator="{ on, attrs }">
                              <v-icon class="grey--text mt-4" v-on="on">mdi-cog-outline</v-icon>
                           </template>
                           <span>Process Data Model</span>
                        </v-tooltip>
                     </template>
                     <template #item.ignoreFailStateResults="{ item }">
                        <v-switch
                           v-if="item.dataModelType === ProjectDataModelType.ProcessDataModel"
                           v-model="item.ignoreFailStateResults"
                           color="error"
                        />
                     </template>
                     <template #item.defaultValue="{ item }">
                        <v-row>
                           <v-switch
                              v-model="item.isDefaultValueEnabled"
                              :readonly="readonly"
                              color="error"
                              @change="defaultValueChanged(item)"
                           />
                           <v-text-field
                              v-if="item.isDefaultValueEnabled"
                              v-model="item.defaultValue"
                              :readonly="readonly"
                           ></v-text-field>
                        </v-row>
                     </template>
                     <template #item.query="{ item }">
                        <code-editor v-model="item.query" language="javascript" class="mt-2" :readonly="readonly" />
                     </template>
                     <template #item.id="{ item }">
                        <span v-if="getQueryResultError(item)">
                           <v-icon color="warning">mdi-exclamation-thick</v-icon>
                           <span>{{ getQueryResultError(item) }}</span>
                        </span>
                        <v-tooltip v-else-if="getQueryResult(item).hasPermission === false" top>
                           <template #activator="{ on, attrs }">
                              <span v-bind="attrs" class="grey--text" v-on="on">n/a</span>
                           </template>
                           <span>Insufficient Permissions/Usage Types to view this value</span>
                        </v-tooltip>
                        <span v-else>
                           {{ getQueryResult(item).result }}
                        </span>
                     </template>
                     <template #item.actions="{ item }">
                        <v-tooltip :disabled="canDeleteQuery(item)" top>
                           <template #activator="{ on, attrs }">
                              <div v-on="on">
                                 <v-btn
                                    icon
                                    class="mt-1"
                                    :disabled="readonly || !canDeleteQuery(item)"
                                    elevation="2"
                                    @click="onRemoveQueryClick(item)"
                                 >
                                    <v-icon color="error">mdi-delete</v-icon>
                                 </v-btn>
                              </div>
                           </template>
                           <span>Query is used in a condition</span>
                        </v-tooltip>
                     </template>
                  </v-data-table>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="auto">
                  <v-row justify="start" dense>
                     <v-col cols="auto" align-self="center">
                        <span>Condition</span>
                     </v-col>
                     <v-col v-if="!readonly" cols="auto" align-self="center">
                        <v-tooltip top>
                           <template #activator="{ on, attrs }">
                              <v-btn
                                 v-bind="attrs"
                                 class="red--text mb-1"
                                 icon
                                 :disabled="!isConditionDefined"
                                 small
                                 v-on="on"
                                 @click="initializeEmpty"
                              >
                                 <v-icon>mdi-close</v-icon>
                              </v-btn>
                           </template>
                           <span>Clear condition</span>
                        </v-tooltip>
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <v-card flat>
                     <vue-query-builder
                        v-if="queryFieldRules && queryFieldRules.length"
                        :key="queryBuilderKey"
                        v-model="condition"
                        :rules="queryFieldRules"
                        :labels="conditionLabels"
                     >
                        <template #default="slotProps">
                           <query-builder-group v-bind="slotProps" :query.sync="condition" :readonly="readonly" />
                        </template>
                     </vue-query-builder>
                  </v-card>
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <h6>Description</h6>
               </v-col>
            </v-row>
            <v-row>
               <v-col v-if="internalValue">
                  <vue-editor v-model="internalValue.description" :disabled="readonly"></vue-editor>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions v-if="!readonly">
            <v-spacer></v-spacer>
            <v-btn
               v-shortkey.once="['ctrl', 's']"
               class="error"
               text
               @shortkey="onSaveClicked()"
               @click="onSaveClicked()"
            >
               Save
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
   ProjectDataModelType,
   GateCondition,
   GateConditionQuery,
   IGateConditionExpression,
   GateConditionExpression,
   ContentBrickExpressionType,
   GatePrimaryExpression,
   GatePrimaryExpressionType,
   ConditionResult,
   ConditionQueryResult,
   ContentBrickFieldDefinition,
} from "@backend/api/pmToolApi";
import QueryBuilderRule from "@models/shared/query-builder/QueryBuilderRule";
import Util from "@models/shared/ContentBrickDefinitionConditionUtils";
import CodeEditor from "@components/Shared/code-editor.vue";
import VueQueryBuilder from "vue-query-builder";
import QueryBuilderGroup from "@components/ContentBricks/TabDetail/QueryBuilder/vuetify-group.vue";
import { VueEditor } from "vue2-editor";
import { Guid } from "guid-typescript";
import { cloneDeep, clone } from "lodash";
import ViewItem from "@models/view/ViewItem";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   name: "PbbGateConditionDialog",
   components: {
      CodeEditor,
      VueQueryBuilder,
      QueryBuilderGroup,
      VueEditor,
   },
})
export default class PbbGateConditionDialog extends Vue {
   ProjectDataModelType: any = ProjectDataModelType;

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   value: GateCondition | null;

   @Prop({ default: null })
   result: ConditionResult | null;

   @Prop({ default: () => [] })
   fields: ContentBrickFieldDefinition[];

   @Prop({ default: "Define Gate condition" })
   title: string;

   @Prop({ default: "Condition" })
   label: string;

   internalValue: GateCondition | null = null;
   queryBuilderKey: number = 1;

   @Watch("showDialog", { deep: true })
   onValueChanged() {
      if (this.showDialog) {
         this.initializeValue();
      }
   }

   get headerQueries(): ViewItem[] {
      return [
         { text: "Name", value: "name", class: "pmtool-table-header-fixed-lg", align: "left" },
         { text: "Type", value: "dataModelType", class: "pmtool-table-header-fixed-xs" },
         { text: "Ignore Fail Results", value: "ignoreFailStateResults", width: 125 },
         { text: "Default value", value: "defaultValue", class: "pmtool-table-header-fixed-lg", align: "left" },
         { text: "JSONPath query", value: "query", align: "left" },
         ...(this.result
            ? [
                 {
                    text: "Current result",
                    value: "id",
                    sortable: false,
                    filterable: false,
                 },
              ]
            : []),
         {
            text: "",
            value: "actions",
            sortable: false,
            filterable: false,
            class: "pmtool-table-header-fixed-xs",
         },
      ];
   }

   get queries() {
      return this.internalValue?.queries ?? [];
   }

   getQueryResultError(query: GateConditionQuery): string | undefined {
      return this.getQueryResult(query)?.error;
   }

   getQueryResult(query: GateConditionQuery): ConditionQueryResult | undefined {
      if (!this.result) return undefined;

      return this.result.queryResults!.find((qr) => qr.queryId === query.id);
   }

   canDeleteQuery(query: GateConditionQuery) {
      const isInUse = JSON.stringify(this.condition).includes(query.id);
      return !isInUse;
   }

   onAddQueryClick(type: ProjectDataModelType) {
      this.internalValue!.queries!.push(
         GateConditionQuery.fromJS({
            id: Guid.create().toString(),
            dataModelType: type,
            ignoreFailStateResults: type === ProjectDataModelType.ProcessDataModel ? true : undefined,
         })
      );
   }

   onRemoveQueryClick(query: GateConditionQuery) {
      if (this.internalValue?.queries) {
         this.internalValue.queries = this.internalValue.queries.filter((i) => i !== query);
      }
   }

   get isConditionDefined() {
      return this.condition?.children?.length;
   }

   initializeValue() {
      this.internalValue = this.value
         ? cloneDeep(this.value)
         : new GateCondition({
              description: undefined,
              queries: [],
              predicate: undefined,
           });

      this.internalValue.predicate ??= new GateConditionExpression({
         expressionType: ContentBrickExpressionType.LogicGroupExpression,
         hasPermission: undefined,
         left: undefined,
         right: undefined,
         operands: [],
         operator: "and",
      });

      this.internalValue.predicate.operator ??= "and";
      this.internalValue.predicate.operands ??= [];
      this.condition = this.expressionToQuery(this.internalValue.predicate);

      this.internalValue.queries = this.internalValue.queries || [];
      this.queryBuilderKey++; //force discard stale data in QueryBuilder
   }

   initializeEmpty() {
      this.condition = {
         logicalOperator: "and",
         children: [],
      };
   }

   updateValue() {
      if (!this.internalValue) return;

      this.internalValue.predicate = this.queryToExpression(this.condition);
      if (JSON.stringify(this.internalValue) !== JSON.stringify(this.value)) {
         this.$emit("input", this.internalValue);
      }
   }

   hideDialog() {
      this.$emit("hideDialog");
   }

   clickClose() {
      this.hideDialog();
   }

   onSaveClicked() {
      this.updateValue();
      this.hideDialog();
   }

   mounted() {
      this.initializeValue();
   }

   // ------- validation -------
   nameRules: ValidationRule[] = [
      (v) => !!v || "Name is required",
      (v) => v!.length <= 80 || "Name must be less than 80 characters",
   ];

   // -------- Query builder -------------
   condition: object = {};

   conditionLabels: object = {
      matchType: "Operator",
      matchTypes: [
         { id: "and", label: "AND" },
         { id: "or", label: "OR" },
         { id: "andnot", label: "AND NOT" },
      ],
      addRule: "Add Condition",
      removeRule: "&times;",
      addGroup: "Add Group",
      removeGroup: "&times;",
      textInputPlaceholder: "value",
   };

   get queryFieldRules(): QueryBuilderRule[] {
      let queryRules = this.getQueryRules();
      let fieldRules = Util.queryFieldRules(this.fields);
      return [...fieldRules, ...queryRules];
   }

   getQueryRules(): QueryBuilderRule[] {
      let rules = this.queries.map((query) => {
         return {
            id: query.id,
            label: query.name ?? "",
            type: "text",
            fields: this.queries
               .filter((of) => of.id !== query.id)
               .map((field) => ({
                  id: field.id,
                  label: field.name,
               })),
            operators: [
               "equals",
               "does not equal",
               "contains",
               "does not contain",
               "is empty",
               "is not empty",
               "begins with",
               "ends with",
               "=",
               "<>",
               "<",
               "<=",
               ">",
               ">=",
            ],
            isGateCondition:
               true /*query editor does to pass props to rules, need to repeat static values on every rule*/,
         };
      });
      return rules;
   }

   queryToExpression(group: any): GateConditionExpression {
      return new GateConditionExpression({
         expressionType: ContentBrickExpressionType.LogicGroupExpression,
         operator: group.logicalOperator,
         operands:
            group.children?.map((child) =>
               child.type === "query-builder-group"
                  ? this.queryToExpression(child.query)
                  : new GateConditionExpression({
                       expressionType: ContentBrickExpressionType.BinaryExpression,
                       operator: child.query.operator,
                       left: new GatePrimaryExpression({
                          type: GatePrimaryExpressionType.Query,
                          value: child.query.rule,
                       }),
                       right: new GatePrimaryExpression({
                          type: child.query.value?.startsWith("ref|")
                             ? GatePrimaryExpressionType.Query
                             : GatePrimaryExpressionType.Constant,
                          value: child.query.value?.startsWith("ref|")
                             ? child.query.value.slice("ref|".length)
                             : child.query.value,
                       }),
                       hasPermission: undefined,
                       operands: [],
                    })
            ) ?? [],
         left: undefined,
         right: undefined,
         hasPermission: undefined,
      });
   }

   expressionToQuery(expression: IGateConditionExpression): any {
      if (expression.expressionType === ContentBrickExpressionType.LogicGroupExpression) {
         return {
            logicalOperator: expression.operator,
            children: expression.operands?.map((op) => ({
               type:
                  op.expressionType === ContentBrickExpressionType.LogicGroupExpression
                     ? "query-builder-group"
                     : "query-builder-rule",
               query: this.expressionToQuery(op),
            })),
         };
      } else {
         let self = this;
         return {
            operator: expression.operator,
            rule: expression.left!.value,
            value:
               expression.right!.type === GatePrimaryExpressionType.Query
                  ? `ref|${expression.right!.value}`
                  : expression.right!.value,
            hasPermission: expression.hasPermission,
            get operand() {
               return self.queries.find((field) => field.id == this.rule)?.name;
            },
         };
      }
   }

   defaultValueChanged(item: GateConditionQuery) {
      if (item.isDefaultValueEnabled !== true) {
         item.defaultValue = undefined;
      }
   }
}
</script>
<style>
.query-table td {
   vertical-align: top;
}

.query-table .code-editor-title {
   display: none;
}

.query-table .code-editor {
   min-height: 35px;
   font-size: 16px;
}
</style>
