<template>
   <v-card>
      <v-card-text>
         <v-form ref="form">
            <v-row>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field
                     ref="nameField"
                     v-model.trim="newListItemText"
                     color="error"
                     label="Text"
                     :rules="textRules"
                     :counter="80"
                     autofocus
                     :readonly="readonly"
                     @blur="onNameBlur"
                     @keyup.enter="onNameConfirmed"
                  />
               </v-col>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field
                     ref="identifierField"
                     v-model.trim="newListItemIdentifier"
                     color="error"
                     label="Identifier"
                     :rules="identifierRules"
                     counter
                     maxlength="80"
                     :readonly="readonly"
                     :append-icon="!readonly ? 'mdi-refresh' : null"
                     @click:append="regenerateIdentifier"
                     @keyup.enter="onAddNewListItemClick"
                  ></v-text-field>
               </v-col>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-btn elevation="2" :disabled="!canAddNewItem" @click="onAddNewListItemClick">
                     <v-icon color="success">mdi-plus</v-icon>
                     <span>Add</span>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
         <v-row>
            <v-col>
               <v-data-table
                  v-sortable-data-table
                  :mobile-breakpoint="0"
                  dense
                  :headers="listItemsHeader"
                  :items="items"
                  item-key="text"
                  class="max-width overview-table pmtool-table"
                  @sorted="saveOrder"
               >
                  <template #item.text="{ item }">
                     <span class="drag-filter">
                        {{ item.text }}
                     </span>
                  </template>
                  <template #item.identifier="{ item }">
                     <span class="drag-filter">
                        {{ item.identifier }}
                     </span>
                  </template>
                  <template #item.action="{ item }">
                     <v-btn icon elevation="2" small class="mr-2" @click.stop.prevent @click="copyItemIdentifier(item)">
                        <v-icon color="blue" small>mdi-content-copy</v-icon>
                     </v-btn>

                     <v-btn
                        icon
                        elevation="2"
                        :disabled="!canMoveFieldUp(item) || readonly"
                        small
                        @click="onMoveFieldUpClick(item)"
                     >
                        <v-icon color="blue" small>mdi-arrow-up</v-icon>
                     </v-btn>
                     <v-btn
                        icon
                        elevation="2"
                        :disabled="!canMoveFieldDown(item) || readonly"
                        small
                        @click="onMoveFieldDownClick(item)"
                     >
                        <v-icon color="blue" small>mdi-arrow-down</v-icon>
                     </v-btn>
                     <v-btn
                        :disabled="readonly"
                        icon
                        elevation="2"
                        small
                        class="ml-2"
                        @click.stop.prevent
                        @click="removeListItem(item)"
                     >
                        <v-icon color="error" small>mdi-delete</v-icon>
                     </v-btn>
                  </template>
               </v-data-table>
            </v-col>
         </v-row>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { List, ListItem, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import Sortable from "sortablejs";
import ValidationRules, { ValidationRule } from "@models/shared/ValidationRules";
import DataModelUtils from "@utils/DataModelUtils";

@Component({
   name: "ListDetailTabSinglecolumnItems",
   components: {},
   directives: {
      sortableDataTable: {
         bind(el, binding, vnode) {
            const options = {
               animation: 150,
               onUpdate: function (event) {
                  vnode.child.$emit("sorted", event);
               },
               filter: ".drag-filter",
            };
            Sortable.create(el.getElementsByTagName("tbody")[0], options);
         },
      },
   },
})
export default class ListDetailTabSinglecolumnItems extends ComponentBase {
   @Prop({ default: null })
   listModel: List | null;

   @Prop({ default: true })
   readonly: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   newListItemText: string = "";
   newListItemIdentifier: string = "";

   textRules: ValidationRule[] = [
      (v) => !!v || "Text is required",
      (v) => v!.length <= 80 || "Text must be less than 80 characters",
      this.isDuplicateItemText,
   ];
   identifierRules: ValidationRule[] = [
      ...ValidationRules.identifier,
      this.isDuplicateItemIdentifier,
      (v) =>
         this.listModel?.items?.find((x) => !x.disabled && x.identifier?.toLowerCase() === v!.toLowerCase()) ===
            undefined || "Identifier is already used for another list item",
   ];

   isDuplicateItemText(text: string): boolean | string {
      return (
         this.listModel?.items?.find((x) => !x.disabled && x.text?.toLowerCase() === text.toLowerCase()) ===
            undefined || "Text is already used for another list item"
      );
   }

   isDuplicateItemIdentifier(identifier: string): boolean | string {
      return (
         this.listModel?.items?.find((x) => !x.disabled && x.identifier?.toLowerCase() === identifier.toLowerCase()) ===
            undefined || "Identifier is already used for another list item"
      );
   }

   onNameBlur(arg, arg2) {
      if (!this.newListItemText) return;

      if (!this.newListItemIdentifier) {
         this.regenerateIdentifier();
      }
   }

   regenerateIdentifier() {
      if (!this.newListItemText) return;

      if (this.newListItemText.length > 0) {
         // when node name has value, and identifier was never set
         this.newListItemIdentifier = DataModelUtils.generateNodeIdentifier(this.newListItemText) ?? ""; // prefill identifier from name
      }
   }

   validateRules(rules: ValidationRule<any>[], value: any): boolean {
      for (let rule of rules) {
         let result = rule(value);
         if (!result || typeof result === "string") {
            return false;
         }
      }
      return true;
   }

   validateItemText(text: string): boolean {
      return this.validateRules(this.textRules, text);
   }

   validateItemIdentifier(identifier: string): boolean {
      return this.validateRules(this.identifierRules, identifier);
   }

   get items(): ListItem[] | undefined {
      return this.listModel?.items?.filter((li) => !li.disabled);
   }

   get canAddNewItem(): boolean {
      if (this.readonly) {
         return false;
      }
      return this.validateItemText(this.newListItemText) && this.validateItemIdentifier(this.newListItemIdentifier);
   }

   onNameConfirmed() {
      this.$refs.identifierField?.focus();
   }

   onAddNewListItemClick() {
      if (!this.canAddNewItem || !this.listModel) {
         return;
      }

      if (!this.listModel.items) {
         this.listModel.items = [];
      }

      let index = this.listModel.items.findIndex((x) => x.identifier === this.newListItemIdentifier);
      let item =
         index > -1
            ? this.listModel.items[index]
            : ListItem.fromJS({
                 disabled: false,
              });
      if (index === -1) {
         index = this.listModel.items.push(item) - 1;
      }
      item.text = this.newListItemText;
      item.identifier = this.newListItemIdentifier;
      item.disabled = false;

      // find first deleted item = index of last item
      let lastItemIndex = this.listModel.items.findIndex((x) => x.disabled);
      lastItemIndex = lastItemIndex === -1 ? this.listModel.items.length - 1 : lastItemIndex;
      // swap deleted and added items
      this.listModel.items[index] = this.listModel.items[lastItemIndex];
      this.listModel.items[lastItemIndex] = item;
      this.newListItemText = "";
      this.newListItemIdentifier = "";
      this.$refs.form?.resetValidation();
      this.$refs.nameField?.focus();
   }

   removeListItem(item: ListItem) {
      if (!this.listModel?.items) {
         return;
      }

      let index = this.listModel.items.findIndex((x) => x.identifier === item.identifier);
      if (index > -1) this.listModel.items.splice(index, 1);
      this.listModel.items.push(item); //move deleted items to the end
      item.disabled = true;
   }

   // -------- Table -------------
   listItemsHeader = [
      { text: "Item text", value: "text", sortable: false },
      { text: "Identifier", value: "identifier", sortable: false },
      { text: "Actions", value: "action", sortable: false },
   ];

   // --------- ListItems_tab manual sorting buttons ---------
   canMoveFieldUp(item: ListItem): boolean {
      return this.listModel!.items![0] !== item;
   }

   canMoveFieldDown(item: ListItem): boolean {
      let index = this.listModel!.items!.findIndex((x) => x.identifier === item.identifier);
      if (index === -1) throw "List item not found";
      return index < this.listModel!.items!.length - 1 && !this.listModel!.items![index + 1].disabled;
   }

   onMoveFieldUpClick(item: ListItem): void {
      if (this.canMoveFieldUp(item)) {
         this.moveFieldBySteps(item, -1);
      }
   }

   onMoveFieldDownClick(item: ListItem): void {
      if (this.canMoveFieldDown(item)) {
         this.moveFieldBySteps(item, 1);
      }
   }

   moveFieldBySteps(item: ListItem, steps: number): void {
      if (!this.listModel?.items) return;

      const fromIdx = this.listModel.items.indexOf(item);

      if (fromIdx !== -1 && this.listModel?.items) {
         const toIdx = Math.min(this.listModel.items.length, Math.max(0, fromIdx + steps));
         this.listModel.items.splice(toIdx, 0, this.listModel.items.splice(fromIdx, 1)[0]); // 1. remove&return moved element 2. insert it at target position
      }
   }

   // ------ Drag and Drop -----
   saveOrder(event) {
      if (!this.listModel?.items) return;

      const movedItem = this.listModel.items.splice(event.oldIndex, 1)[0];
      this.listModel.items.splice(event.newIndex, 0, movedItem);
   }

   copyItemIdentifier(item: ListItem) {
      if (item.identifier) {
         navigator.clipboard.writeText(item.identifier);
         this.notifySuccess("copied to clipboard", "List item Identifier");
      }
   }
}
</script>
