<template>
   <div v-if="hasValueFields">
      <div class="d-inline-flex align-center">
         <h5 class="mb-0 mr-1">{{ translateKey("contentTab.metadataFieldsLabel", translations) }}</h5>
         <template>
            <v-btn elevation="2" :disabled="readonly" class="mx-1" @click="showAddFieldDialog()">
               <v-icon color="success">mdi-plus</v-icon>
               <span>{{ translateKey("button.add", translations) }}</span>
            </v-btn>
            <v-tooltip top>
               <template #activator="{ on }">
                  <v-btn class="mx-1" icon elevation="2" @click="onExpandButtonClicked()">
                     <v-icon v-on="on">mdi-arrow-expand-down</v-icon>
                  </v-btn>
               </template>
               <span>{{ translateKey("button.expand", translations) }}</span>
            </v-tooltip>
            <v-tooltip top>
               <template #activator="{ on }">
                  <v-btn class="mx-1" icon elevation="2" @click="onCollapseButtonClicked()">
                     <v-icon v-on="on">mdi-arrow-expand-up</v-icon>
                  </v-btn>
               </template>
               <span>{{ translateKey("button.collapse", translations) }}</span>
            </v-tooltip>
         </template>
      </div>
      <v-data-table
         :key="tableUpdateKey"
         v-sortable-data-table
         :mobile-breakpoint="0"
         class="pmtool-table-overview nonclickable"
         dense
         :headers="headerFields"
         :items="allFields"
         hide-default-footer
         disable-pagination
         no-data-text="No fields defined. You can add fields via the 'Add' button"
         :show-expand="false"
         :expanded="expanded"
         :item-class="getRowClass"
         @sorted="onSorted"
      >
         <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
               <div class="d-flex">
                  <div :style="{ width: `${(item._depth - 1) * 30}px` }" class="tree-padding"></div>
                  <div class="px-4 flex-grow-1">
                     <content-brick-field-detail
                        v-model="item"
                        :contentBrick="contentBrick"
                        :readonly="readonly"
                        :isNew="isNew"
                        :hasChanges="hasChanges"
                        :attachments="attachments"
                        :attachmentsLoading="attachmentsLoading"
                        :mediaUrls="mediaUrls"
                        :mediaLoading="mediaLoading"
                        :regularExpressions="regularExpressions"
                        :translations="translations"
                        @mediaUploaded="downloadMediaUrls"
                        @attachment-modified="$emit('attachment-modified', $event)"
                     ></content-brick-field-detail>
                  </div>
               </div>
            </td>
         </template>
         <template #item.name="{ item }">
            <div class="d-flex align-items-center h-100">
               <div :style="{ width: `${(item._depth - 1) * 30}px` }" class="tree-padding"></div>
               <v-icon
                  :color="expanded.length === 0 ? 'warning' : 'gray'"
                  title="Drag to new position"
                  :class="{ 'drag-item': expanded.length === 0 }"
               >
                  mdi-drag
               </v-icon>
               <v-btn v-if="Util.isField(item)" class="ml-1" icon @click="toggleExpand(item)">
                  <v-icon
                     class="mdi mdi-chevron-down"
                     :class="{ 'v-data-table__expand-icon--active': expanded.includes(item) }"
                  ></v-icon>
               </v-btn>
               <v-tooltip top>
                  <template #activator="{ on }">
                     <v-icon class="mx-2" v-on="on">{{ getFieldTypeIcon(item) }}</v-icon>
                  </template>
                  <span>{{ getFieldTypeName(item) }}</span>
               </v-tooltip>
               <v-text-field
                  v-model="item.name"
                  :rules="nameRules"
                  maxlength="80"
                  style="min-width: 200px"
                  :readonly="readonly"
                  @blur="EventBus.$emit(Events.P2P, { eId: item.id })"
               ></v-text-field>
            </div>
         </template>
         <template #item.identifier="{ item }">
            <identifier-field
               v-model="item.identifier"
               :readonly="readonly"
               :source="item.name"
               :eventId="item.id"
               :label="translateKey('contentTab.identifierHeader', translations)"
            ></identifier-field>
         </template>
         <template #item.type="{ item }">
            <v-autocomplete
               v-if="Util.isField(item)"
               v-model="item.type"
               :items="fieldTypeComboItems"
               :item-text="(item) => getDecoratorText(item, translations)"
               item-value="value"
               :readonly="readonly || isUsedInCondition(item)"
               @change="onFieldTypeValueChanged(item)"
            >
               <template #append-outer>
                  <v-tooltip v-if="isUsedInCondition(item)" top>
                     <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" color="grey" v-on="on">mdi-lock</v-icon>
                     </template>
                     <div>
                        {{ translateKey("contentTab.conditionReferencesLabel", translations) }}
                        <div v-for="ref in findReferencingConditions(item)">
                           {{ ref.name }}
                        </div>
                     </div>
                  </v-tooltip>
               </template>
            </v-autocomplete>
         </template>
         <template #item.others="{ item }">
            <content-brick-field-inline
               v-if="Util.isField(item)"
               v-model="item"
               :readonly="readonly"
               :getUnits="getUnits"
               :getUnitTypes="getUnitTypes"
               :getLists="getLists"
               :getSubContentBricks="getSubContentBricks"
               :subCbReferences="subCbReferences"
               :loadingScbReferences="loadingScbReferences"
               @show-combobox-dialog="showComboboxDialog"
               @set-list-value="setListValue"
               @load-scb-requested="loadMissingSubContentBricks"
            ></content-brick-field-inline>
         </template>
         <template #item.actions="{ item }">
            <div class="d-flex align-center justify-content-end">
               <template>
                  <v-tooltip v-if="isTextAreaField(item)" top>
                     <template #activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           color="error"
                           class="round-none"
                           icon
                           elevation="2"
                           v-on="on"
                           @click="showEditorModal(item)"
                        >
                           <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                     </template>
                     <span>Show text editor</span>
                  </v-tooltip>
               </template>
               <v-tooltip v-if="Util.isField(item)" top>
                  <template #activator="{ on, attrs }">
                     <div v-bind="attrs" v-on="on">
                        <!--to-do optimize out of dt-->
                        <add-reference-dialog
                           :value="getRegularExpressionValue(item.regularExpressionId)"
                           entity="Formatting"
                           :apiEndpoint="() => getRegularExpressions(item)"
                           newItemRoute="/regularExpressions/new"
                           itemDetailRoute="/regularExpressions/detail"
                           :disabled="readonly || !isRegularExpressionEnabled(item)"
                           :singleSelect="true"
                           :allowNone="true"
                           :sortDesc="true"
                           class="ml-3"
                           :showOnlyButton="true"
                           icon="mdi-regex"
                           @input="setRegularExpressionValue(item, $event)"
                        ></add-reference-dialog>
                     </div>
                  </template>
                  <span>Formatting</span>
               </v-tooltip>
               <v-tooltip top>
                  <template #activator="{ on, attrs }">
                     <div v-bind="attrs" v-on="on">
                        <v-btn
                           class="round-none ml-3"
                           icon
                           elevation="2"
                           :disabled="readonly || isUsedInCondition(item)"
                           @click="onRemoveFieldClick(item)"
                        >
                           <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                     </div>
                  </template>
                  <span v-if="!isUsedInCondition(item)">{{ translateKey("button.delete", translations) }}</span>
                  <div v-else>
                     {{ translateKey("contentTab.conditionReferencesLabel", translations) }}
                     <div v-for="ref in findReferencingConditions(item)">
                        {{ ref.name }}
                     </div>
                  </div>
               </v-tooltip>
            </div>
         </template>
      </v-data-table>
      <content-brick-add-field-dialog
         :show-dialog="isAddFieldDialogShown"
         :fieldTypes="fieldTypeComboItems"
         :booleanLayouts="fieldBooleanLayoutComboItems"
         :units="units"
         :isValueFieldMode="isAddFieldDialogInValueFieldMode"
         :loadingDependencies="loadingDependencies"
         :getUnits="getUnits"
         :getUnitTypes="getUnitTypes"
         :getLists="getLists"
         :getRegularExpressions="getRegularExpressions"
         :loadRegularExpressionsByIds="loadRegularExpressionsByIds"
         :getSubContentBricks="getSubContentBricks"
         :headerUnits="headerUnits"
         :headerUnitTypes="headerUnitTypes"
         :fields="enactmentFields"
         :metadataFields="metadataFields"
         :isUnitFixedDefaultValue="true"
         @addContentBrickField="onAddContentBrickField"
         @hideDetailDialog="hideAddFieldDialog"
         @reloadDependencies="$emit('reloadDependencies')"
      ></content-brick-add-field-dialog>
      <content-brick-add-group-dialog-async ref="addGroupDialog"></content-brick-add-group-dialog-async>
      <content-brick-combobox-dialog
         :show-dialog="isComboboxDialogShown"
         :content-brick-field="selectedComboboxField"
         :disabled="readonly"
         @updateCombobox="onUpdateCombobox"
         @hideDialog="hideComboboxDialog"
      ></content-brick-combobox-dialog>
      <rich-text-area-dialog
         v-if="selectedItem"
         v-model="selectedItemRichText"
         :readonly="readonly"
         :showDialog.sync="showTextAreaModal"
         :title="selectedItem.name"
      ></rich-text-area-dialog>
   </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import BaseResponse from "@models/BaseResponse";
import Sortable from "sortablejs";
import {
   ListApi,
   ListType,
   ContentBrickFieldType,
   FormattingType,
   RegularExpression,
   EntityStatus,
   ItemReference,
   TranslationPublicModel,
   UnitReference,
   ContentBrickFieldDefinition,
   QueryOptionsOfListFilterOptions,
   ListFilterOptions,
   SubContentBrickDefinitionReference,
   QueryOptionsOfSubContentBrickDefinitionFilterOptions,
   SubContentBrickDefinitionFilterOptions,
   SubContentBrickDefinitionApi,
   DesignGuidelineValueFieldDefinition,
   ContentBrickDefinition,
   ContentBrickFieldGroupDefinition,
   FieldCalculationDefinition,
   List,
   DesignGuidelineFieldEvaluation,
   ListItem,
   ContentBrickDefinitionApi,
   AttachmentMetadata,
   AttachmentDownloadModel,
   GateConditionExpression,
   GatePrimaryExpressionType,
   GateCondition,
   ContentBrickExpressionType,
   ContentBrickType,
} from "@backend/api/pmToolApi";
import DesignGuidelineFieldType from "@models/shared/DesignGuidelineFieldType";
import ViewItem from "@models/view/ViewItem";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import { BooleanFieldLayoutDecorator } from "@models/shared/BooleanFieldLayoutDecorator";
import UnitCachedApi from "@backend/store/apiCache/UnitCachedApi";
import UnitTypeCachedApi from "@backend/store/apiCache/UnitTypeCachedApi";
import RegularExpressionCachedApi from "@backend/store/apiCache/RegularExpressionCachedApi";
import GlobalStore from "@backend/store/globalStore.ts";
import ContentBrickAddFieldDialog from "@components/ContentBricks/TabDetail/content-brick-add-field-dialog.vue";
import ContentBrickAddGroupDialogAsync from "@components/ContentBricks/TabDetail/content-brick-add-group-dialog-async.vue";
import ContentBrickFieldInline from "@components/ContentBricks/TabDetail/FieldDefinition/content-brick-field-inline.vue";
import ContentBrickFieldDetail from "@components/ContentBricks/TabDetail/FieldDefinition/content-brick-field-detail.vue";
import ContentBrickCalculationDialog from "@components/ContentBricks/Shared/content-brick-calculation-dialog.vue";
import {
   ContentBrickTreeNodeTypeUtils as Util,
   ContentBrickFieldTypeUtils as FieldUtil,
   FieldDefinition,
   FieldTreeNode,
   FieldTreeConditionNode,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import AddReferenceDialog from "@components/Shared/add-reference-dialog.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import IdentifierField from "@components/Shared/identifier-field.vue";
import xor from "lodash/xor";
import cloneDeep from "lodash/cloneDeep";
import flatMapDeep from "lodash/flatMapDeep";
import ContentBrickComboboxDialog from "@components/ContentBricks/Shared/content-brick-combobox-dialog.vue";
import AttachmentUtils from "@utils/AttachmentUtils";
import { ImageTypeDecorator } from "@models/shared/imageTypeDecorator";
import { VideoTypeDecorator } from "@models/shared/VideoTypeDecorator";
import PbbGateConditionDialog from "@components/PBB/Shared/pbb-gate-condition-dialog.vue";
import ConditionIcon from "@components/PBB/Shared/condition-icon.vue";
import RichTextAreaDialog from "@components/Shared/rich-text-area-dialog.vue";
import ConditionUtils from "@utils/ConditionUtils";
import ContentBrickUtils from "@utils/ContentBrickUtils";
import { Guid } from "guid-typescript";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   name: "ContentBrickMetadataFieldsTable",
   components: {
      ContentBrickAddFieldDialog,
      ContentBrickCalculationDialog,
      ContentBrickAddGroupDialogAsync,
      AddReferenceDialog,
      IdentifierField,
      ContentBrickFieldInline,
      ContentBrickFieldDetail,
      ContentBrickComboboxDialog,
      PbbGateConditionDialog,
      RichTextAreaDialog,
      ConditionIcon,
   },
   directives: {
      sortableDataTable: {
         bind(el, binding, vnode) {
            const options = {
               animation: 150,
               onUpdate: function (event) {
                  vnode.child.$emit("sorted", event);
               },
               filter: ".drag-filter",
               handle: ".drag-item",
            };
            Sortable.create(el.getElementsByTagName("tbody")[0], options);
         },
      },
   },
})
export default class ContentBrickMetadataFieldsTable extends ComponentBase {
   @Prop({ required: true })
   contentBrickType: ContentBrickType;

   @Prop({ default: false })
   loadingDependencies: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: false })
   isNew: boolean;

   @Prop({ required: true })
   hasChanges: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true, default: () => [] })
   units: UnitReference[];

   @Prop({ required: true })
   contentBrick: ContentBrickDefinition;

   @Prop({ default: () => {} })
   attachments: { [key: string]: AttachmentMetadata };

   @Prop({ default: false })
   attachmentsLoading: boolean;

   Util: any = Util;
   Events: any = Events;
   EventBus: any = EventBus;
   ConditionUtils = ConditionUtils;
   DesignGuidelineFieldType = DesignGuidelineFieldType;
   subCbReferences: SubContentBrickDefinitionReference[] = [];

   get hasValueFields(): boolean {
      return ContentBrickTypeDecorator.get(this.contentBrickType).hasValueFields;
   }

   // -------- Expansion -------------
   expanded: FieldTreeNode[] = [];

   onExpandButtonClicked() {
      this.expanded = this.allFields;
   }

   onCollapseButtonClicked() {
      this.expanded = [];
   }

   toggleExpand(node: FieldTreeNode) {
      this.expanded = xor(this.expanded, [node]);
   }

   // -------- Headers -------------
   get headerFields(): DataTableHeader[] {
      return [
         {
            text: this.translateKey("contentTab.nameHeader", this.translations),
            value: "name",
            width: "1%",
         },
         {
            text: this.translateKey("contentTab.identifierHeader", this.translations),
            value: "identifier",
            class: "pmtool-table-header-fixed-md",
         },
         {
            text: this.translateKey("contentTab.dataTypeHeader", this.translations),
            value: "type",
            class: "pmtool-table-header-fixed-xxsm",
         },
         {
            text: this.translateKey("contentTab.othersHeader", this.translations),
            value: "others",
            class: "pmtool-table-header-fixed-xlg",
            sortable: false,
         },
         { text: "", value: "actions", sortable: false },
      ];
   }

   headerUnits: ViewItem[] = [{ text: "Name", value: "displayText" }];
   headerUnitTypes: ViewItem[] = [{ text: "Name", value: "displayText" }];

   mounted() {
      if (this.contentBrick?.id) {
         this.mediaPreload();
      }
   }

   // ------- Attachments -------
   mediaUrls: { [key: string]: string } = {};
   mediaPreloading: number = 0;

   get mediaLoading(): boolean {
      return !!this.mediaPreloading;
   }

   async downloadMediaUrls(mediaBlobs: string[]): Promise<void> {
      if (!mediaBlobs || !mediaBlobs.length) {
         return;
      }

      try {
         this.mediaPreloading++;

         for (let value of mediaBlobs) {
            var file = await this.generateMediaLink(value);

            if (file) {
               this.mediaUrls[file.blobName!] = file.downloadUrl!;
            }
         }
      } finally {
         this.mediaPreloading--;
      }
   }

   async generateMediaLink(blobName: string): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         this.mediaPreloading++;
         // Call the API FileResponse
         let file = ContentBrickDefinitionApi.generateAttachmentDownloadUrl(this.contentBrick.id, blobName);
         return file;
      } catch (error) {
         this.notifyError(error, "download", "DesignGuideline Attachment");
      } finally {
         this.mediaPreloading--;
      }
   }

   async mediaPreload() {
      try {
         this.mediaPreloading++;
         this.contentBrick?.designGuidelineMetadataFields
            ?.filter((f) => AttachmentUtils.isMediaField(f.type))
            .forEach(async (f) => {
               if (AttachmentUtils.isMediaField(f.type)) {
                  let mediaBlobs = f.value?.filter((url) => !this.mediaUrls[url]) ?? [];
                  await this.downloadMediaUrls(mediaBlobs);
               }
            });
      } catch (error) {
         this.notifyError(error, "preload", "attachment media");
      } finally {
         this.mediaPreloading--;
      }
   }

   // ------- Combobox -------
   isComboboxDialogShown: boolean = false;
   selectedComboboxField: FieldDefinition | null = null;

   showComboboxDialog(field: FieldDefinition): void {
      this.selectedComboboxField = field;
      this.isComboboxDialogShown = true;
   }

   hideComboboxDialog(): void {
      this.isComboboxDialogShown = false;
   }

   onUpdateCombobox(list: List): void {
      if (this.selectedComboboxField) {
         this.selectedComboboxField.list = cloneDeep(list);
      }
   }

   // -------- Fields -------------
   tableUpdateKey: number = 0; // table recycles items into wrong rows, nuke it from orbit
   allFieldsInternal: DesignGuidelineValueFieldDefinition[] | null = null;

   isUsedInCondition(field: FieldDefinition): boolean {
      return !!this.findReferencingConditions(field).length;
   }

   findReferencingConditions(field: FieldDefinition): FieldTreeNode[] {
      return this.flatFieldTree.filter((treeNode) => {
         if (Util.isValueField(treeNode)) {
            const valueField = treeNode as DesignGuidelineValueFieldDefinition;
            return this.isFieldReferenced(valueField.condition?.predicate, field);
         } else if (Util.isGroup(treeNode)) {
            const group = treeNode as ContentBrickFieldGroupDefinition;
            return (
               this.isFieldReferenced(group.condition?.predicate, field) ||
               this.isFieldReferenced(group.visibilityCondition?.predicate, field)
            );
         } else if (Util.isContentBrickField(treeNode)) {
            return false;
         } else {
            throw "Invalid/unadled Content Brick definition Field tree node type";
         }
      });
   }

   isFieldReferenced(expression: GateConditionExpression | null | undefined, field: FieldDefinition): boolean {
      if (!expression?.operands?.length || !field?.id) return false;

      const flatten = (item: GateConditionExpression) => [item, flatMapDeep(item?.operands ?? [], flatten)];
      const flattenedTree = flatMapDeep([expression], flatten) as GateConditionExpression[];

      return flattenedTree.some(
         (expr) =>
            (expr.left?.type === GatePrimaryExpressionType.Query && expr.left.value === field.id) ||
            (expr.right?.type === GatePrimaryExpressionType.Query && expr.right.value === field.id)
      );
   }

   isTextAreaField(field: FieldDefinition): boolean {
      return field.type === ContentBrickFieldType.TextArea;
   }

   onFieldTypeValueChanged(field: FieldDefinition) {
      ContentBrickUtils.resetFieldTypeSpecificValues(field);

      if (field.type === ContentBrickFieldType.Calculated && !field.fieldCalculation) {
         this.$set(
            field,
            "fieldCalculation",
            new FieldCalculationDefinition({
               queries: [],
               formula: "",
            })
         );
      }

      if (field.type === ContentBrickFieldType.ComboBox && !field.fieldCalculation) {
         this.$set(
            field,
            "list",
            List.fromJS({
               items: [],
            })
         );
      }

      if (AttachmentUtils.isImageField(field.type) && this.imageTypeComboItems.length == 1) {
         field.imageType = this.imageTypeComboItems[0].value;
      } else if (AttachmentUtils.isVideoField(field.type) && this.videoTypeComboItems.length === 1) {
         field.videoType = this.videoTypeComboItems[0].value;
      }

      if (!this.isRegularExpressionEnabled(field)) {
         field.regularExpressionId = undefined;
      }
   }

   get imageTypeComboItems() {
      return ImageTypeDecorator.AllItems;
   }

   get videoTypeComboItems() {
      return VideoTypeDecorator.AllItems;
   }

   get lists(): List[] {
      return this.fields?.filter((f) => FieldUtil.isListField(f) && f.list)?.map((f) => f.list!) ?? [];
   }

   get allFields(): DesignGuidelineValueFieldDefinition[] {
      if (!this.allFieldsInternal) {
         this.allFieldsInternal = this.metadataFields.sort(Util.compareNodes);
      }

      return this.allFieldsInternal;
   }

   set allFields(value: DesignGuidelineValueFieldDefinition[]) {
      // set both fields and DGL value fields
      this.contentBrick.designGuidelineMetadataFields = value;
      this.allFieldsInternal = this.metadataFields.sort(Util.compareNodes);
   }

   get enactmentFields() {
      return this.fields.concat(this.valueFields).sort(Util.compareNodes);
   }

   get fields(): ContentBrickFieldDefinition[] {
      return this.contentBrick?.fields ? this.contentBrick.fields : [];
   }

   get valueFields(): DesignGuidelineValueFieldDefinition[] {
      return this.contentBrick?.designGuidelineValueFields ?? [];
   }

   get metadataFields(): DesignGuidelineValueFieldDefinition[] {
      return this.contentBrick?.designGuidelineMetadataFields ?? [];
   }

   onSorted(event: { oldIndex: number; newIndex: number }) {
      const groups = this.flatFieldTree;
      const dragEntity = groups[event.oldIndex];
      const dropEntity = groups[event.newIndex];
      const dragParent = dragEntity["_parent"] as ContentBrickFieldGroupDefinition;
      const dropParent = dropEntity["_parent"] as ContentBrickFieldGroupDefinition;
      const delta = event.oldIndex < event.newIndex ? 0.1 : -0.1; // some small value, any really
      dragEntity.order = dropEntity.order + delta;
      // compact indices to force integer numbers in the tree
      this.compactOrder();
      this.tableUpdateKey++;
   }

   // ------------ Regular Expression ------------
   regularExpressions: RegularExpression[] = [];
   // Loads regularExpressions of all contentBrick fields and designGuideLines fields
   async loadFieldsRegularExpressions(id: string | undefined = undefined) {
      let regularExpressionIds: string[] = [];

      // load regularExpression Ids from contentBrick fields
      if (this.fields && this.fields.length > 0) {
         this.fields.forEach((field) => {
            if (field.regularExpressionId && regularExpressionIds.indexOf(field.regularExpressionId) === -1) {
               regularExpressionIds.push(field.regularExpressionId);
            }
         });
      }

      // load regularExpression Ids from designGuideLines fields
      if (this.contentBrick?.designGuidelineValueFields && this.contentBrick.designGuidelineValueFields.length > 0) {
         this.contentBrick.designGuidelineValueFields.forEach((dgField) => {
            if (dgField.regularExpressionId && regularExpressionIds.indexOf(dgField.regularExpressionId) === -1) {
               regularExpressionIds.push(dgField.regularExpressionId);
            }
         });
      }

      // add expternal incoming regularExpression Id if exist
      if (id && regularExpressionIds.indexOf(id) === -1) {
         regularExpressionIds.push(id);
      }

      if (regularExpressionIds.length > 0) {
         this.regularExpressions = (await this.loadRegularExpressionsByIds(regularExpressionIds)) ?? [];
      } else {
         this.regularExpressions = [];
      }
   }

   isRegularExpressionEnabled(field: FieldDefinition): boolean {
      return RegularExpressionUtils.isRegularExpressionEnabled(field?.type);
   }

   getRegularExpressionValue(regularExpressionId: string | undefined): ItemReference | undefined {
      if (!regularExpressionId) {
         return undefined;
      }
      var regularExpression = this.regularExpressions.find((r) => r.id === regularExpressionId);

      if (regularExpression) {
         return new ItemReference({
            id: regularExpression.id,
            code: regularExpression.code,
            entityStatus: regularExpression.entityStatus,
            displayText: regularExpression.name,
            lastModified: regularExpression.lastModified,
            created: undefined,
         });
      } else {
         return new ItemReference({
            id: regularExpressionId,
            code: undefined,
            entityStatus: undefined,
            lastModified: undefined,
            displayText: undefined,
            created: undefined,
         });
      }
   }

   async setRegularExpressionValue(field: FieldDefinition, regularExpressionReference: ItemReference | undefined) {
      if (field) {
         if (regularExpressionReference?.id) {
            await this.reloadMissingRegularExpressions(regularExpressionReference.id);
         }
         this.$set(field, "regularExpressionId", regularExpressionReference?.id ?? undefined);
      }
   }

   async reloadMissingRegularExpressions(regularExpressionId: string | undefined) {
      if (regularExpressionId && this.regularExpressions.findIndex((r) => r.id === regularExpressionId) < 0) {
         // no regularExpression found, reload regularExpressions
         await this.loadFieldsRegularExpressions(regularExpressionId);
      }
   }

   // -------- Table columns -------------
   getFieldTypeName(node: FieldTreeNode): string {
      return this.translateKey(Util.get(node).translationKey, this.translations);
   }

   getFieldTypeIcon(node: FieldTreeNode): string {
      return Util.get(node).icon;
   }

   getRowClass(node: FieldTreeNode) {
      return Util.isGroup(node) ? "row-group" : "";
   }

   // -------- Table items -------------
   get flatFieldTree(): FieldTreeNode[] {
      return this.allFields;
   }

   compactOrder() {
      if (!this.contentBrick.designGuidelineMetadataFields) return;

      this.contentBrick.designGuidelineMetadataFields.sort(Util.compareNodes);
      for (let i = 0; i < this.contentBrick.designGuidelineMetadataFields.length; i++) {
         this.contentBrick.designGuidelineMetadataFields[i].order = i;
      }
   }

   // ----------- wysiwyg editor ------------------
   showTextAreaModal: boolean = false;

   showEditorModal(field: FieldTreeConditionNode) {
      this.selectedItem = field;
      this.showTextAreaModal = true;
   }

   get selectedItemRichText(): string | undefined {
      if (!this.selectedItem) return undefined;

      if (Util.isGroup(this.selectedItem)) {
         return (this.selectedItem as ContentBrickFieldGroupDefinition)?.description;
      } else {
         return (this.selectedItem as DesignGuidelineValueFieldDefinition)?.value;
      }
   }

   set selectedItemRichText(value: string | undefined) {
      if (!this.selectedItem) return;

      if (Util.isGroup(this.selectedItem)) {
         (this.selectedItem as ContentBrickFieldGroupDefinition).description = value;
      } else {
         (this.selectedItem as DesignGuidelineValueFieldDefinition).value = value;
      }
   }

   // -------- Add Field Dialog -------------
   get fieldTypeComboItems() {
      return ContentBrickFieldTypeDecorator.MetadataFieldItems;
   }

   get fieldBooleanLayoutComboItems() {
      return BooleanFieldLayoutDecorator.AllItems;
   }

   isAddFieldDialogShown: boolean = false;
   isAddFieldDialogInValueFieldMode: boolean = true;
   showAddFieldDialog(): void {
      this.showAddFieldValueDialog();
   }

   showAddFieldValueDialog(): void {
      this.isAddFieldDialogShown = true;
   }

   hideAddFieldDialog(): void {
      this.isAddFieldDialogShown = false;
   }

   async onAddContentBrickField(field: DesignGuidelineValueFieldDefinition): Promise<void> {
      await this.reloadMissingList(field, field.listId);
      await this.reloadMissingRegularExpressions(field.regularExpressionId);

      field.evaluationType = DesignGuidelineFieldEvaluation.Disabled;
      field.condition = new GateCondition({
         description: undefined,
         predicate: new GateConditionExpression({
            expressionType: ContentBrickExpressionType.LogicGroupExpression,
            operands: [],
            operator: "and",
            left: undefined,
            right: undefined,
            hasPermission: undefined,
         }),
         queries: [],
      });
      this.allFields.push(field);
      this.allFields = this.allFields; // set fields to write changes to underlying arrays

      field.order = Number.MAX_SAFE_INTEGER;
      field.groupId = Guid.EMPTY;
      field.isHiddenInTask = true;
      this.compactOrder();

      this.hideAddFieldDialog();
   }

   onRemoveFieldClick(node: FieldTreeNode) {
      this.allFields = this.allFields.filter((c) => c !== node);
      this.compactOrder();
   }

   async setListValue(field: FieldDefinition, listReference: ItemReference | undefined) {
      await this.reloadMissingList(field, listReference?.id);
      this.$set(field, "listId", listReference?.id ?? null);
   }

   async reloadMissingList(field: FieldDefinition, listId: string | undefined) {
      if (field && listId /*do not try to fetch unsaved Combo lists*/) {
         let fullList = this.lists.find((l) => l.id === listId);
         fullList ??= await this.loadList(listId);
         this.$set(field, "list", fullList ?? null);
      }
   }

   async loadMissingSubContentBricks(subContentBrickCode: string | undefined) {
      if (subContentBrickCode && this.subCbReferences.findIndex((scb) => scb.code === subContentBrickCode) < 0) {
         // scb not found, reload scbs
         await this.loadSubContentBrickReferences();
      }
   }

   // -------condition Dialog-------
   selectedItem: FieldTreeConditionNode | null = null;

   // ------- validation -------
   nameRules: ValidationRule[] = [
      (v) => !!v?.trim() || "Name is required",
      (v) => v!.length <= 80 || "Name must be less than 80 characters",
   ];

   // -------- API -------------
   loadingList: boolean = false;
   loadingScbReferences: boolean = false;
   loadingRegularExpressionReferences: boolean = false;

   async loadList(listId: string | undefined): Promise<List | undefined> {
      if (!listId) {
         return undefined;
      }

      this.loadingList = true;
      try {
         let result = await ListApi.getList(listId);
         return result;
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/List");
      } finally {
         this.loadingList = false;
      }
   }

   async getUnits(field: ContentBrickFieldDefinition): Promise<UnitReference[] | undefined> {
      let res = await UnitCachedApi.getAllUnitReferences(EntityStatus.Active, field.unitType?.id);
      return res;
   }

   async getUnitTypes(): Promise<ItemReference[] | undefined> {
      let res = await UnitTypeCachedApi.getActiveUnitTypes();
      return res;
   }

   async getLists(): Promise<ItemReference[] | undefined> {
      let queryOptions = new QueryOptionsOfListFilterOptions();
      queryOptions.filter = new ListFilterOptions({
         entityStatus: EntityStatus.Active,
         entityCodes: undefined,
         excludeEntityCodes: undefined,
         lastVisited: undefined,
         createdBy: undefined,
         searchQuery: undefined,
         lastOnly: true,
         tags: undefined,
         searchIdentifiers: undefined,
         lastOnlyAnyStatus: false,
         type: ListType.List,
         entityIds: undefined,
         entityStatuses: undefined,
      });
      let res = await ListApi.queryAllListReferences(queryOptions);
      return res.documents;
   }

   async getRegularExpressions(
      field: ContentBrickFieldDefinition | undefined = undefined
   ): Promise<ItemReference[] | undefined> {
      try {
         const formattingType: FormattingType | undefined =
            field?.type === ContentBrickFieldType.Integer ||
            field?.type === ContentBrickFieldType.Decimal ||
            field?.type === ContentBrickFieldType.Calculated
               ? FormattingType.Number
               : undefined;

         let result = await RegularExpressionCachedApi.getRegularExpressionReferences(
            undefined,
            EntityStatus.Active,
            formattingType
         );

         return result;
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/Formatting");
      }
   }

   async loadRegularExpressionsByIds(ids: string[]): Promise<RegularExpression[] | undefined> {
      this.loadingRegularExpressionReferences = true;
      try {
         let result = await RegularExpressionCachedApi.getRegularExpressions(ids);
         return result ?? [];
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/Formatting");
      } finally {
         this.loadingRegularExpressionReferences = false;
      }
   }

   async getSubContentBricks(): Promise<SubContentBrickDefinitionReference[] | undefined> {
      let queryOptions = new QueryOptionsOfSubContentBrickDefinitionFilterOptions();
      queryOptions.filter = new SubContentBrickDefinitionFilterOptions({
         entityStatus: EntityStatus.Active,
         entityCodes: undefined,
         excludeEntityCodes: undefined,
         lastVisited: undefined,
         createdBy: undefined,
         searchQuery: undefined,
         lastOnly: true,
         tags: undefined,
         searchIdentifiers: undefined,
         identifiers: [],
         domains: [GlobalStore.getDomain()],
         lastOnlyAnyStatus: false,
         entityStatuses: undefined,
      });
      let res = await SubContentBrickDefinitionApi.getSubContentBrickDefinitionReferences(queryOptions);
      return res.documents;
   }

   async loadSubContentBrickReferences(): Promise<void> {
      this.loadingScbReferences = true;
      try {
         // Call the API
         let queryOptions = new QueryOptionsOfSubContentBrickDefinitionFilterOptions();
         queryOptions.pageSize = -1; // all scbs
         queryOptions.filter = SubContentBrickDefinitionFilterOptions.fromJS({
            domains: this.contentBrick ? [this.contentBrick.domain] : undefined,
            entityStatus: EntityStatus.Active,
            entityCodes: this.contentBrick
               ? this.contentBrick.fields!.filter((f) => f.subContentBrickCode).map((f) => f.subContentBrickCode!)
               : undefined,
            lastOnly: true,
         });

         let result = await SubContentBrickDefinitionApi.getSubContentBrickDefinitionReferences(queryOptions);
         // Process/Save data etc.
         this.subCbReferences = result.documents ?? [];
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/SubContentBricks");
      }
      this.loadingScbReferences = false;
   }
}
</script>
<style lang="scss" scoped>
::v-deep .tree-padding {
   background-color: var(--white);
   align-self: stretch;
   cursor: initial;
}

::v-deep tr:not(:hover).row-group {
   background-color: var(--v-bg240-lighten1);
}
</style>
