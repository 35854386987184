<template>
   <v-tooltip bottom :disabled="!shouldShowResult(value)">
      <template #activator="{ on, attrs }">
         <v-badge overlap bottom color="transparent" offset-x="16" offset-y="16" :value="shouldShowResult(value)">
            <v-icon dense color="grey" v-on="on">{{ getContentBrickIcon(value) }}</v-icon>
            <template #badge>
               <v-icon :color="getContionCheckColor(value.recursiveResult)" size="16" class="check-bg" v-on="on">
                  {{ getContionResultIcon(value.recursiveResult) }}
               </v-icon>
            </template>
         </v-badge>
      </template>
      <span>{{ getResultTooltip(value.recursiveResult) }}</span>
   </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { TranslationPublicModel, DesignGuidelineFieldEvaluation } from "@backend/api/pmToolApi";
import DesignGuidelineResultDecorator from "@models/shared/DesignGuidelineResultDecorator";
import { TestResultTreeNode } from "@models/testReport/TestResultTree";
import DesignGuidelineNodeTypeDecorator from "@models/shared/DesignGuidelineNodeResultDecorator";

@Component({
   name: "TaskTestReportNodeIcon",
   components: {},
})
export default class TaskTestReportNodeIcon extends ComponentBase {
   @Prop({ required: true })
   value: TestResultTreeNode;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   // ------------- Group Result icon ----------------
   shouldShowResult(testResult: TestResultTreeNode): boolean {
      return testResult.hasCondition && testResult.evaluationType !== DesignGuidelineFieldEvaluation.Disabled;
   }

   getContentBrickIcon(testResult: TestResultTreeNode): string | undefined {
      return DesignGuidelineNodeTypeDecorator.get(testResult.type).icon;
   }

   getContionResultIcon(result: boolean | undefined): string | undefined {
      return DesignGuidelineResultDecorator.get(result).icon;
   }

   getContionCheckColor(result: boolean | undefined): string | undefined {
      return DesignGuidelineResultDecorator.get(result).color;
   }

   getResultTooltip(result: boolean | undefined): string {
      const translationKey = DesignGuidelineResultDecorator.get(result).translationKey;
      return this.translateKey(translationKey, this.translations);
   }
}
</script>
<style scoped lang="scss"></style>
