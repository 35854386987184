import { Domain, DomainApi } from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";

class DomainCachedApi extends CachedApiBase {
   invalidateAll() {
      this.invalidateItem("DomainCachedApi.getDomains");
   }

   /**
    * Returns all domains
    * @return List of all Domains
    */
   async getDomains(): Promise<Domain[]> {
      return await this.cacheEndpointAsync<Domain>(
         "DomainCachedApi.getDomains",
         DomainApi.getDomains.bind(DomainApi),
         Domain.fromJS
      );
   }

   /**
    * Returns domain by its domainId value
    * @param domainId domainId value of the requested Domain
    * @return Domain with the given domainId value
    */
   async getDomainByDomainId(domainId: number): Promise<Domain> {
      const allDomains = await this.getDomains();
      const domain = allDomains.find((d) => d.domainId === domainId);

      if (domain) {
         return domain;
      } else {
         throw `Domain ${domainId} not found`;
      }
   }

   /**
    * Returns list of all domains wihch are active and an user has permissions to see them
    * @return List of all Domains
    */
   async getDomainsForMenu(): Promise<Domain[]> {
      const allDomains = await this.getDomains();
      const menuDomains = allDomains.filter((d) => d.isActive && d.permissions?.domainAccess === true);
      return menuDomains;
   }

   private static _instance: DomainCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): DomainCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default DomainCachedApi.instance;
