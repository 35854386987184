// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!../../../../node_modules/bootstrap/dist/css/bootstrap.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".document-type-input[data-v-0092121a]{width:277px}.value-field-document-type-input[data-v-0092121a]{width:400px}.value-field-text-area-type-input[data-v-0092121a]{width:150px}.value-field-expanded-component[data-v-0092121a]{padding-right:30px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
