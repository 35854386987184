<template>
   <v-dialog
      v-if="showDialog && contentBrickField"
      :value="showDialog"
      persistent
      scrollable
      width="75%"
      @input="hideDetailDialog"
   >
      <v-card>
         <v-card-title primary-title>
            <span>{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn class="red--text" icon @click="clickClose">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <list-detail-tab-items :listModel="listModel" :readonly="disabled"></list-detail-tab-items>
         </v-card-text>

         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!disabled" color="error" outlined @click="clickClose()">Discard</v-btn>
            <v-btn class="error" text @click="onSaveClicked()">{{ saveButtonTitle }}</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IContentBrickFieldDefinition, IList, List } from "@backend/api/pmToolApi";
import { cloneDeep } from "lodash";
import ListDetailTabItems from "@components/Lists/TabDetail/list-detail-tab-items.vue";

@Component({
   name: "ContentBrickComboboxDialog",
   components: {
      ListDetailTabItems,
   },
})
export default class ContentBrickComboboxDialog extends Vue {
   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: null })
   contentBrickField: IContentBrickFieldDefinition;

   @Prop({ default: false })
   disabled: boolean;

   listModel: IList | null = null;

   @Watch("showDialog", { deep: true })
   onValueChanged(): void {
      if (this.showDialog) {
         this.listModel = cloneDeep(
            this.contentBrickField.list ??
               List.fromJS({
                  items: [],
               })
         );
      }
   }

   get saveButtonTitle(): string {
      return this.disabled ? "Close" : "Update";
   }

   get title(): string {
      return `${this.contentBrickField.name} items`;
   }

   // ------ Dialog -----
   hideDetailDialog(): void {
      this.$emit("hideDialog");
   }

   clickClose(): void {
      this.hideDetailDialog();
   }

   onSaveClicked(): void {
      if (!this.disabled) {
         this.$emit("updateCombobox", this.listModel!);
      }
      this.hideDetailDialog();
   }
}
</script>
