<template>
   <div>
      <v-card>
         <v-card-title>
            <v-row justify="start">
               <v-col cols="auto" class="pt-0 pb-0" align-self="center">
                  <span>{{ title }}</span>
               </v-col>
            </v-row>
         </v-card-title>
         <v-card-text>
            <v-col>
               <v-data-table :items="items" :headers="headers" :loading="loading || units.loading">
                  <template #item.result.result="{ item }">
                     <v-tooltip v-if="item.result.result === true" bottom>
                        <template #activator="{ on, attrs }">
                           <v-icon v-bind="attrs" color="green" v-on="on">mdi-check-circle</v-icon>
                        </template>
                        <span>Passed</span>
                     </v-tooltip>
                     <v-tooltip v-else-if="item.result.result === false" bottom>
                        <template #activator="{ on, attrs }">
                           <v-icon v-bind="attrs" color="red" v-on="on">mdi-alert-circle-outline</v-icon>
                        </template>
                        <span>Failed</span>
                     </v-tooltip>
                     <v-tooltip v-else bottom>
                        <template #activator="{ on, attrs }">
                           <v-icon v-bind="attrs" color="gray" v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <span>Not Evaluated</span>
                     </v-tooltip>
                  </template>
                  <template #item.actions="{ item }">
                     <v-btn class="white" icon elevation="2" @click="showDgReport(item)">
                        <v-icon>mdi-cube-scan</v-icon>
                     </v-btn>
                  </template>
               </v-data-table>
            </v-col>
         </v-card-text>
      </v-card>
      <task-test-report-dialog
         ref="designGuidelineReportDialog"
         :units="units"
         :attachments="attachments"
         :referencedLists="referencedLists"
         :referencedTextAreas="referencedTextAreas"
         :readonly="true"
         :translations="translations"
      ></task-test-report-dialog>
   </div>
</template>

<script lang="ts">
import {
   IUnit,
   AttachmentMetadata,
   ContentBrickDefinitionApi,
   TranslationPublicModel,
   List,
   TextArea,
   TaskDesignGuidelineReport,
} from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import BaseResponse from "@models/BaseResponse";
import SharedLookup from "@utils/SharedLookup";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitCachedApi from "@backend/store/apiCache/UnitCachedApi";
import TaskTestReportDialog from "@components/Tasklist/TaskDetailComponents/TestReport/task-test-report-dialog.vue";
import DesignGuidelineReportRow from "@models/dataModel/DesignGuidelineReportRow";

@Component({
   components: {
      TaskTestReportDialog,
   },
})
export default class DesignGuidelineDetailTabReportOverview extends ComponentBase {
   loading: boolean = false;

   @Prop()
   dglId: string;

   @Prop()
   attachments: { [key: string]: AttachmentMetadata };

   @Prop({ default: "References" })
   title: string;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   items: DesignGuidelineReportRow[] = [];
   referencedLists: { [key: string]: List } = {};
   referencedTextAreas: { [key: string]: TextArea } = {};

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.headers, this.translations);
   }

   async loadData() {
      try {
         this.loading = true;
         const result = await ContentBrickDefinitionApi.getProjectDesignGuidelineReports(this.dglId);
         this.referencedLists = result.referencedLists ?? {};
         this.referencedTextAreas = result.referencedTextAreas ?? {};
         this.items =
            result.items?.flatMap((report) =>
               report.designGuidelineResults!.map((result) => {
                  return {
                     projectId: report.projectId,
                     projectCode: report.projectCode,
                     taskId: report.taskId,
                     taskCode: report.taskCode,
                     lastModified: report.lastModified,
                     result: result,
                     report: new TaskDesignGuidelineReport({ ...report, designGuidelineResults: [result] }),
                  };
               })
            ) ?? [];
      } catch (error) {
         this.notifyError(error, "load", "DesignGuidelines");
      } finally {
         this.loading = false;
      }
   }

   async showDgReport(item: DesignGuidelineReportRow): Promise<void> {
      let designGuidelineReportDialog = <any>this.$refs["designGuidelineReportDialog"]!;
      await designGuidelineReportDialog.showDialogAsync(item.report);
   }

   mounted() {
      this.translateHeaders(this.headers, this.translations);
      this.loadData();
   }

   headers = [
      { text: "Code", value: "result.contentBrickCode", translationKey: "reportsTab.codeHeader" },
      { text: "Name", value: "result.contentBrickName", translationKey: "reportsTab.nameHeader" },
      { text: "Result", value: "result.result", translationKey: "reportsTab.resultHeader" },
      { text: "", value: "actions", translationKey: "reportsTab.actionsHeader" },
   ];

   units: SharedLookup<IUnit> = new SharedLookup<IUnit>(this.loadUnits);

   async loadUnits(): Promise<IUnit[] | undefined> {
      try {
         let units = await UnitCachedApi.getAllUnits();
         return units;
      } catch (error) {
         this.notifyError(error, "load", "Units");
      }
   }
}
</script>

<style scoped></style>
