export default interface BaseResponse<T = any> {
   success: boolean;
   message: string;
   status?: number;
   errors?: object;
   data?: T;
}

export function isBaseResponse(x: unknown): x is BaseResponse {
   return typeof x === "object" && x != null && "message" in x;
}
