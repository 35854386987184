<template>
   <v-card v-if="contentBrick">
      <v-card-title primary-title>
         <v-row>
            <v-col cols="12" md="6">
               {{ translateKey("dmiReferencesTab.dmiReferencesLabel", translations) }}
            </v-col>
            <v-col cols="12" md="6">
               <div class="d-flex">
                  <v-text-field
                     v-model="searchDataModels"
                     append-icon="mdi-magnify"
                     :label="translateKey('label.search', translations)"
                     single-line
                     hide-details
                     color="red darken-2"
                     autofocus
                  />
               </div>
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-data-table
            v-model="selectedReferences"
            item-key="contentBrickNodeId"
            show-select
            :mobile-breakpoint="0"
            class="overview-table pmtool-table"
            dense
            :headers="headerDataModels"
            :items="dataModels"
            :loading="loading"
            :search="searchDataModels"
            :footer-props="footerProps"
         >
            <template #item.domainDataModelInstanceCode="{ item }">
               <router-link :to="getDataModelInstanceDetailRouteValue(item)" class="black--text">
                  {{ item.domainDataModelInstanceCode }}
               </router-link>
            </template>
            <template #item.domainDataModelInstanceName="{ item }">
               <router-link :to="getDataModelInstanceDetailRouteValue(item)" class="black--text">
                  {{ item.domainDataModelInstanceName }}
               </router-link>
            </template>
            <template #item.contentBrickNodePath="{ item }">
               <router-link :to="getDataModelInstanceDetailRouteValue(item)" class="black--text">
                  {{ item.contentBrickNodePath }}
               </router-link>
            </template>
            <template #item.contentBrickNodeName="{ item }">
               <router-link :to="getDataModelInstanceDetailRouteValue(item)" class="black--text">
                  {{ item.contentBrickNodeName }}
               </router-link>
            </template>
            <template #item.contentBrickVersion="{ item }">
               <router-link :to="getDataModelInstanceDetailRouteValue(item)" class="black--text">
                  {{ item.contentBrickVersion }}
               </router-link>
            </template>
         </v-data-table>
         <v-tooltip top>
            <template #activator="{ on, attrs }">
               <v-btn
                  class="error"
                  v-bind="attrs"
                  :disabled="selectedReferences.length < 1"
                  :loading="versionUpdatingInProgress"
                  v-on="on"
                  @click="updateSelectedReferencesVersions"
               >
                  {{ translateKey("dmiReferencesTab.updateSelectedReferencesButton", translations) }}
               </v-btn>
            </template>
            <span>{{ translateKey("dmiReferencesTab.updateSelectedReferencesButtonTooltip", translations) }}</span>
         </v-tooltip>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseResponse, { isBaseResponse } from "@models/BaseResponse";
import {
   BatchContentBrickVersionUpdateRequest,
   ContentBrickDefinitionApi,
   DomainDataModelInstanceApi,
   IContentBrickDefinition,
   IContentBrickInDomainDataModelInstanceReference,
   TranslationPublicModel,
   VersionedFilterOptions,
} from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "ContentBrickDetailTabDmiUsage",
   components: {},
})
export default class ContentBrickDetailTabDmiUsage extends ComponentBase {
   loading: boolean = true;
   dataModels: IContentBrickInDomainDataModelInstanceReference[] = [];
   filter: VersionedFilterOptions = VersionedFilterOptions.fromJS({ lastOnly: true });
   searchDataModels: string = "";
   footerProps = { "items-per-page-options": [10, 20, 50, 100] };
   selectedReferences: IContentBrickInDomainDataModelInstanceReference[] = [];
   versionUpdatingInProgress: boolean = false;

   @Prop({ default: null })
   contentBrick: IContentBrickDefinition | null;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.headerDataModels, this.translations);
   }

   // ---- table -----
   headerDataModels = [
      {
         text: "Code",
         value: "domainDataModelInstanceCode",
         translationKey: "dmiReferencesTab.codeHeader",
         width: 120,
      },
      { text: "DMI Name", value: "domainDataModelInstanceName", translationKey: "dmiReferencesTab.nameHeader" },
      { text: "Node Path", value: "contentBrickNodePath", translationKey: "dmiReferencesTab.pathHeader" },
      { text: "Node Name", value: "contentBrickNodeName", translationKey: "dmiReferencesTab.nodeNameHeader" },
      {
         text: "Version",
         value: "contentBrickVersion",
         translationKey: "dmiReferencesTab.versionHeader",
         class: "pmtool-table-header-fixed-sm",
      },
   ];

   getDataModelInstanceDetailRouteValue(
      item: { domainDataModelInstanceId: string } & IContentBrickInDomainDataModelInstanceReference
   ) {
      return {
         name: "domain-data-model-instance-detail",
         params: { id: item.domainDataModelInstanceId },
      };
   }

   mounted() {
      this.loadContentBrickDefinitionDataModelReferences();
      this.translateHeaders(this.headerDataModels, this.translations);
   }

   // ---- API -----
   async loadContentBrickDefinitionDataModelReferences(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         this.dataModels = await ContentBrickDefinitionApi.getAllContentBrickDefinitionDataModelInstances(
            this.contentBrick.code
         );
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition DomainDataModelInstance references");
      } finally {
         this.loading = false;
      }
      for (const row of this.dataModels) {
         row.isSelectable = row.contentBrickVersion != this.contentBrick?.version?.major;
      }
   }

   async updateSelectedReferencesVersions(): Promise<void> {
      this.versionUpdatingInProgress = true;
      try {
         const response = await DomainDataModelInstanceApi.updateContentBricksToLastVersion(
            this.selectedReferences.map(
               (x) =>
                  new BatchContentBrickVersionUpdateRequest({
                     dmiId: x.domainDataModelInstanceId,
                     cbNodeId: x.contentBrickNodeId,
                  })
            )
         );
         const updated = response.filter((x) => x.isUpdated);
         const upToDate = response.filter((x) => !x.isUpdated);
         const amount = (amount: number, entity: string) => `${amount} ${entity}${amount === 1 ? "" : "s"}`;
         if (updated.length > 0) {
            const newVersion = String(updated[0].newVersion).padStart(4, "0");
            const message = `The version of ${amount(
               updated.length,
               "ContentBrick"
            )} has been updated to v${newVersion}`;
            EventBus.$emit(Events.DisplayToast, {
               color: "success",
               text: message,
            });
         }
         if (upToDate.length > 0) {
            const message = `The version of ${amount(upToDate.length, "ContentBrick")} is already up-to-date.`;
            EventBus.$emit(Events.DisplayToast, {
               color: "info",
               text: message,
            });
         }
      } catch (e) {
         if (isBaseResponse(e)) {
            console.log("API DomainDataModelsInstance references version update error:", e);
            this.notifyError(e, "update", "DomainDataModelsInstance references version");
            return;
         }
         throw e;
      } finally {
         this.selectedReferences = [];
         this.versionUpdatingInProgress = false;
      }
      await this.loadContentBrickDefinitionDataModelReferences();
   }
}
</script>
