<template>
   <v-card>
      <v-navigation-drawer :mini-variant="miniMenu" permanent app width="245" mini-variant-width="100" class="nav-bar">
         <v-list-item :class="miniMenu ? 'pl-4' : 'px-4'" class="mt-4 mb-2" to="/" :color="selectedColor">
            <v-list-item-avatar tile>
               <v-img :src="logo"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
               <span class="nav-menu-title">PMP</span>
            </v-list-item-title>
            <v-btn v-if="showResizable" icon @click.stop.prevent="miniMenu = !miniMenu">
               <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
         </v-list-item>
         <v-list-item v-if="miniMenu && showResizable">
            <v-btn icon @click.stop.prevent="miniMenu = !miniMenu">
               <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
         </v-list-item>

         <v-list class="mt-0" dense>
            <v-list-item v-if="showDomainSelection" :class="miniMenu ? 'm-0' : 'mb-4'">
               <v-select
                  v-if="!miniMenu"
                  v-model="selectedDomain"
                  :disabled="isDomainChangeDisabled"
                  :items="domains"
                  item-text="name"
                  item-value="domainId"
                  :color="selectedColor"
                  :label="translateKey('navigationMenu.domainsSelectionLabel')"
                  return-object
                  hide-details
                  dense
               ></v-select>

               <v-menu v-else close-on-content-click offset-y :disabled="isDomainChangeDisabled" :color="selectedColor">
                  <template #activator="{ on, attrs }">
                     <v-btn v-bind="attrs" text :color="selectedColor" height="100%" min-height="56px" small v-on="on">
                        <span class="nav-bar-domain-select-text">
                           {{ smallDomainText }}
                        </span>
                     </v-btn>
                  </template>

                  <v-list>
                     <v-list-item v-for="domain in domains" :key="domain.domainId" @click="selectDomain(domain)">
                        <v-list-item-title>
                           {{ domain.name }}
                        </v-list-item-title>
                     </v-list-item>
                  </v-list>
               </v-menu>
            </v-list-item>
            <component
               :is="isGroup(child) ? 'nav-item-group-component' : 'nav-item-leaf-component'"
               v-for="child in availableNavItems"
               :key="child.uiSelector"
               :item="child"
               :selected-color="selectedColor"
               :translations="routeTranslations"
               :is-active="isActive"
            />
            <spinner v-if="!!loadingMenuItems" />
         </v-list>
      </v-navigation-drawer>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import Events from "@models/shared/Events";
import Logo from "@resources/einhell-logo.png";
import GlobalStore from "@backend/store/globalStore";
import BaseResponse from "@models/BaseResponse";
import { Domain, UserApi, MenuItemVisibility, MenuItem } from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { AxiosKeycloak } from "@backend/axiosKeycloak";
import Spinner from "@components/Shared/spinner.vue";
import DomainCachedApi from "@backend/store/apiCache/DomainCachedApi";
import { filterLeafsAndPruneEmptyNodes, navItems, isGroup, NavItem } from "@components/NavMenu/navItems";
import { routesByName } from "@root/routes";
import NavItemLeafComponent from "@components/NavMenu/nav-item-leaf.vue";
import NavItemGroupComponent from "@components/NavMenu/nav-item-group.vue";

@Component({
   name: "NavMenu",
   components: {
      Spinner,
      NavItemLeafComponent,
      NavItemGroupComponent,
   },
})
export default class NavMenu extends ComponentBase {
   idEnum: number = 1;
   selectedColor: string = "red darken-3";
   miniMenu: boolean = false;
   selectedDomain: Domain | null = null;
   logo = Logo;
   userName: string = "";
   domains: Domain[] = [];
   visibleMenuItems: MenuItemVisibility | null = null;
   loadingMenuItems: number = 0;
   isGroup = isGroup;

   @Prop({ default: false })
   mini: boolean;

   get availableNavItems() {
      return filterLeafsAndPruneEmptyNodes(
         navItems,
         (x) => x.menuItem === undefined || this.availableMenuItems.includes(x.menuItem)
      );
   }

   @Watch("mini")
   onMiniValueChanged() {
      this.miniMenu = this.mini;
   }

   @Watch("selectedDomain", { deep: true })
   onSelectedDomainValueChanged() {
      if (this.selectedDomain) {
         this.selectedColor = this.domains.filter((x) => x.domainId === this.selectedDomain?.domainId)[0].color!;
         if (this.selectedDomain.domainId !== GlobalStore.getDomain()) {
            GlobalStore.setDomain(this.selectedDomain.domainId);
            EventBus.$emit(Events.DomainChanged);
         }
      }
   }

   @Watch("availableNavItems")
   onItemsLoaded() {
      if (this.loadingMenuItems < 1) {
         this.$nextTick(() => {
            document.querySelector(".v-list-item.selected-item")?.scrollIntoView();
         });
      }
   }

   get isDomainChangeDisabled(): boolean {
      return this.$root.isDomainChangeDisabled;
   }
   async loadDomains(): Promise<void> {
      try {
         this.loadingMenuItems = Math.min(this.loadingMenuItems + 1, 2);
         let result = await DomainCachedApi.getDomainsForMenu();
         this.domains = result ? this.sortDomains(result) : result;
      } catch (error) {
         this.notifyError(error, "load", "Domains");
      } finally {
         this.loadingMenuItems = Math.max(0, this.loadingMenuItems - 1);
      }
   }

   async loadMenuItemsPermissions(): Promise<void> {
      try {
         this.loadingMenuItems = Math.min(this.loadingMenuItems + 1, 2);
         this.visibleMenuItems = await UserApi.getAvailableMenuItems();
      } catch (error) {
         this.notifyError(error, "load", "Menu items");
      } finally {
         this.loadingMenuItems = Math.max(0, this.loadingMenuItems - 1);
      }
   }

   validateRolesAndSetDomain() {
      if (this.hasAnyRoles) {
         if (this.domains.length == 0) {
            this.notifyError(`User does not have assigned any domain access`);
            return;
         }

         let currentDomain = this.domains.find((x) => x.domainId == GlobalStore.getDomain());

         this.selectDomain(currentDomain ?? this.domains[0]);
      }
   }

   get hasAnyRoles(): boolean {
      let keyCloak = AxiosKeycloak.getKeycloak();
      return (
         !!keyCloak.realmAccess?.roles?.includes("pm_user") &&
         (!!Object.keys(this.visibleMenuItems?.domainMenuItems ?? {}).length ||
            !!this.visibleMenuItems?.systemMenuItems?.length)
      );
   }

   get showDomainSelection(): boolean {
      return this.domains.length !== 0;
   }

   mounted() {
      Promise.all([this.loadMenuItemsPermissions(), this.loadDomains()]).then(() => {
         this.validateRolesAndSetDomain();
      });
      this.loadRouteTranslations("navMenu");

      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations("navMenu");
      });
      var base = this;
      EventBus.$on(Events.UserLoggedIn, () => {
         base.$nextTick(() => {
            base.$forceUpdate();
         });
      });
      EventBus.$on(Events.UserLoggedOut, () => {
         base.$nextTick(() => {
            base.$forceUpdate();
         });
      });
      EventBus.$on(Events.ReloadDomains, async () => {
         await this.loadDomains();
         this.validateRolesAndSetDomain();
      });
      EventBus.$on(Events.ChangeDomain, () => {
         this.changeDomain();
      });
   }

   get showResizable(): boolean {
      return !this.$vuetify.breakpoint.mobile;
   }

   get smallDomainText(): string {
      return this.selectedDomain?.name ?? "";
   }

   selectDomain(domain) {
      this.selectedDomain = domain;
   }

   changeDomain() {
      this.selectDomain(this.domains.find((x) => GlobalStore.getDomain() === x.domainId));
   }

   sortDomains(domains: Domain[]): Domain[] {
      return domains.sort((a, b) => {
         if (a.name === undefined && b.name === undefined) {
            return 0;
         }
         if (a.name === undefined) {
            return 1;
         }
         if (b.name === undefined) {
            return -1;
         }
         return a.name.localeCompare(b.name);
      });
   }

   get availableMenuItems(): MenuItem[] {
      if (!this.visibleMenuItems) {
         return [];
      }

      let items = this.visibleMenuItems.systemMenuItems;
      if (this.selectedDomain?.domainId !== undefined) {
         items = items?.concat(this.visibleMenuItems.domainMenuItems?.[this.selectedDomain.domainId] ?? []);
      }

      return items ?? [];
   }

   isActive(item: NavItem) {
      return isGroup(item)
         ? item.children.some(this.isActive)
         : routesByName[this.$route.name!]?.navValue === routesByName[item.location.name!]?.navValue;
   }
}
</script>

<style scoped>
.nav-bar {
   border-radius: 0px !important;
}

.nav-menu-title {
   font-size: 1.7rem;
}

.nav-bar-domain-select-text {
   display: block;
   width: 90px;
   word-wrap: break-word;
   white-space: normal;
}
</style>
