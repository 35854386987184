<template>
   <v-dialog v-model="showDialog" width="500" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>Confirm</span>
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col>Do you really want to delete {{ itemType }} ?</v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text" outlined ui-test-data="delete-cancel-btn" @click="cancelClick">Cancel</v-btn>
            <v-btn class="error" text ui-test-data="delete-confirm-btn" @click="deleteClick">Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
   name: "ConfirmDeleteDialog",
   components: {},
})
export default class ConfirmDeleteDialog extends Vue {
   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: "item" })
   itemType: string;

   cancelClick() {
      this.$emit("cancelDeleteItem");
   }

   deleteClick() {
      this.$emit("confirmDeleteItem");
   }
}
</script>
<style scoped></style>
