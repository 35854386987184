import {
   ContentBrickFieldDefinition,
   DesignGuidelineValueFieldDefinition,
   ContentBrickFieldGroupDefinition,
   ContentBrickFieldType,
} from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export type FieldDefinition = ContentBrickFieldDefinition | DesignGuidelineValueFieldDefinition;
export type FieldTreeNode = FieldDefinition | ContentBrickFieldGroupDefinition;
export type FieldTreeConditionNode = DesignGuidelineValueFieldDefinition | ContentBrickFieldGroupDefinition;

export enum ContentBrickTreeNodeType {
   Group = 1,
   Field = 2,
   ValueField = 3,
}

export interface IContentBrickTreeNodeProperties extends IEnumBaseProperties {
   icon: string;
}

export class ContentBrickFieldTypeUtils {
   static isListField(field: FieldDefinition): boolean {
      return (
         field.type === ContentBrickFieldType.List ||
         field.type === ContentBrickFieldType.MultiSelectList ||
         field.type === ContentBrickFieldType.ComboBox
      );
   }
}

export class ContentBrickTreeNodeTypeUtils implements IContentBrickTreeNodeProperties {
   value: ContentBrickTreeNodeType;
   private static readonly unknown: number = -1;

   constructor(value: ContentBrickTreeNodeType | FieldTreeNode) {
      if (typeof value === "number") {
         this.value = value;
      } else {
         this.value = ContentBrickTreeNodeTypeUtils.detectType(value);
      }
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get icon(): string {
      return this.properties.icon;
   }

   static get AllItems(): ContentBrickTreeNodeTypeUtils[] {
      return Array.from(ContentBrickTreeNodeTypeUtils.nodeProperties.keys())
         .filter((type: number) => type !== ContentBrickTreeNodeTypeUtils.unknown)
         .map((type: number) => new ContentBrickTreeNodeTypeUtils(type));
   }

   static get(valueOrInstance: ContentBrickTreeNodeType | FieldTreeNode): IContentBrickTreeNodeProperties {
      const value: number =
         typeof valueOrInstance === "number" ? (valueOrInstance as number) : this.detectType(valueOrInstance);
      return ContentBrickTreeNodeTypeUtils.properties(value);
   }

   static compareNodes(a: FieldTreeNode, b: FieldTreeNode): number {
      return a.order - b.order;
   }

   private static detectType(node: FieldTreeNode): ContentBrickTreeNodeType {
      if (this.isGroup(node)) {
         return ContentBrickTreeNodeType.Group;
      }
      if (this.isContentBrickField(node)) {
         return ContentBrickTreeNodeType.Field;
      }
      if (this.isValueField(node)) {
         return ContentBrickTreeNodeType.ValueField;
      }
      throw `Unsupported node type. Field ID: '${node.id}', name: '${node.name}'`;
   }

   private get properties(): IContentBrickTreeNodeProperties {
      return ContentBrickTreeNodeTypeUtils.properties(this.value);
   }

   private static properties(value: ContentBrickTreeNodeType): IContentBrickTreeNodeProperties {
      return (
         ContentBrickTreeNodeTypeUtils.nodeProperties.get(value) ??
         ContentBrickTreeNodeTypeUtils.nodeProperties.get(ContentBrickTreeNodeTypeUtils.unknown)!
      );
   }

   private static readonly nodeProperties: Map<number, IContentBrickTreeNodeProperties> = new Map<
      number,
      IContentBrickTreeNodeProperties
   >([
      [
         ContentBrickTreeNodeType.Group,
         {
            translationKey: "contentBrickTreeNodeType.group",
            icon: "mdi-file-tree-outline",
         },
      ],
      [
         ContentBrickTreeNodeType.Field,
         {
            translationKey: "contentBrickTreeNodeType.field",
            icon: "mdi-cube",
         },
      ],
      [
         ContentBrickTreeNodeType.ValueField,
         {
            translationKey: "contentBrickTreeNodeType.valueField",
            icon: "mdi-cube-scan",
         },
      ],
   ]);

   public static isValueFieldGuard(node: FieldTreeNode): node is DesignGuidelineValueFieldDefinition {
      return this.isValueField(node);
   }

   public static isValueField(node: FieldTreeNode): boolean {
      return this.isField(node) && node["evaluationType"] !== undefined;
   }

   public static isField(node: FieldTreeNode): boolean {
      return node["isUnitFixed"] !== undefined;
   }

   public static isGroupGuard(node: FieldTreeNode): node is ContentBrickFieldGroupDefinition {
      return this.isGroup(node);
   }

   public static isGroup(node: FieldTreeNode): boolean {
      return !this.isField(node);
   }

   public static isContentBrickFieldGuard(node: FieldTreeNode): node is ContentBrickFieldDefinition {
      return this.isContentBrickField(node);
   }

   public static isContentBrickField(node: FieldTreeNode): boolean {
      return this.isField(node) && !this.isValueField(node);
   }
}
