<template>
   <div>
      <template v-for="node in nodes">
         <template v-if="Util.isGroup(node)">
            <v-expansion-panels accordion class="field-group" :value="0">
               <v-expansion-panel :class="depthClass">
                  <v-expansion-panel-header class="pl-3">
                     <div>
                        <strong>{{ node.name }}</strong>
                        <br />
                        <div v-html="getDescription(node)"></div>
                     </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     <design-guideline-field-group
                        :parentGroup="node"
                        :fields="fields"
                        :depth="depth + 1"
                        :attachments="attachments"
                        :mediaUrls="mediaUrls"
                        :contentBrick="contentBrick"
                        :attachmentsLoading="attachmentsLoading"
                     ></design-guideline-field-group>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
         </template>
         <div v-else :key="node.id" class="field-group-child-field">
            <design-guideline-field
               :field="node"
               :attachments="attachments"
               :mediaUrls="mediaUrls"
               :contentBrick="contentBrick"
               :attachmentsLoading="attachmentsLoading"
            ></design-guideline-field>
         </div>
      </template>
   </div>
</template>

<script lang="ts">
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { Component, Prop } from "vue-property-decorator";
import { AttachmentMetadata, ContentBrickFieldGroupDefinition, IContentBrickDefinition } from "@backend/api/pmToolApi";
import DesignGuidelineField from "@components/ContentBricks/SimpleView/design-guideline-field.vue";
import {
   ContentBrickTreeNodeTypeUtils,
   FieldDefinition,
   FieldTreeNode,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";

@Component({
   name: "DesignGuidelineFieldGroup",
   components: { DesignGuidelineField },
})
export default class DesignGuidelineFieldGroup extends ComponentBase {
   @Prop({ required: true })
   parentGroup: ContentBrickFieldGroupDefinition;

   @Prop({ required: true })
   fields: FieldDefinition[];

   @Prop({ required: true })
   attachments: { [key: string]: AttachmentMetadata } | undefined;

   @Prop({ default: 0 })
   depth: number;

   @Prop({ default: () => {} })
   mediaUrls: { [key: string]: string };

   @Prop({ required: true })
   contentBrick: IContentBrickDefinition;

   @Prop({ default: false })
   attachmentsLoading: boolean;

   Util = ContentBrickTreeNodeTypeUtils;
   nodes: FieldTreeNode[] = [];
   metadataNodes: FieldTreeNode[] = [];

   mounted() {
      const filteredFields = this.fields.filter((f) => f.groupId === this.parentGroup.id);
      this.nodes = [...filteredFields, ...(this.parentGroup.children || [])];
      this.nodes.sort((a, b) => {
         return a.order - b.order;
      });
   }

   get depthClass(): string {
      return `depth-${this.depth % 4}`;
   }

   getDescription(node: FieldTreeNode): string | undefined {
      if (this.Util.isGroup(node)) {
         return (node as ContentBrickFieldGroupDefinition).description?.value;
      }

      return undefined;
   }
}
</script>
