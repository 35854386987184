import AppSettings from "@backend/store/settingStore";
import { AxiosKeycloak } from "@backend/axiosKeycloak";

export class MaintenanceUtils {
   get isMaintenance(): boolean {
      return !!AppSettings.getSetting("IsMaintenance");
   }

   get isKeycloakAdmin(): boolean {
      if (!this.isMaintenance) {
         return false;
      }

      const keycloak = AxiosKeycloak.getKeycloak();
      return !!keycloak.realmAccess?.roles.includes("pm_admin");
   }
}
export default new MaintenanceUtils();
