import {
   IContentBrickFieldDefinition,
   ContentBrickFieldType,
   DesignGuidelineValueFieldDefinition,
   DesignGuidelineFieldEvaluation,
   EntityStatus,
   IContentBrickDefinition,
   ContentBrickType,
} from "@backend/api/pmToolApi";
import { FieldDefinition, FieldTreeNode } from "@models/shared/ContentBrickTreeNodeTypeUtils";
import { ContentBrickTreeNodeTypeUtils } from "@models/shared/ContentBrickTreeNodeTypeUtils";

class ContentBrickUtils {
   /**
    * Gets fields, which can be used for a calculation field formula
    * @param currentContentBrickField Field for which calculatable fields are to be used
    * @param contentBrickFields All fields to filter
    * @returns List of usable fields in a calculation formula, transformed list of value (identifier), text (name) object, sorted by name
    */
   public getCalculatableContentBrickFields(
      currentContentBrickField: IContentBrickFieldDefinition,
      contentBrickFields: IContentBrickFieldDefinition[]
   ): IFieldCalculationReference[] {
      return contentBrickFields
         .filter(
            (x) =>
               x.identifier /*saity check, fields without identifier are unusable anyway*/ &&
               (x.type === ContentBrickFieldType.Integer || x.type === ContentBrickFieldType.Decimal) &&
               x.identifier !== currentContentBrickField.identifier &&
               ![null, undefined, ""].includes(x.identifier)
         )
         .map((x) => ({
            id: x.id,
            name: x.name ?? "Unknown field",
            identifier: x.identifier!,
         }));
   }

   public flattenDfsPreorder(
      node: FieldTreeNode | undefined | null,
      parent: FieldTreeNode | null = null,
      depth: number = 0,
      result: FieldTreeNode[] = [],
      fields: FieldTreeNode[]
   ): FieldTreeNode[] {
      if (!node) return result;

      Object.defineProperty(node, "_depth", {
         value: depth,
         configurable: true,
         writable: false,
         enumerable: false,
      });
      Object.defineProperty(node, "_parent", {
         value: parent,
         configurable: true,
         writable: false,
         enumerable: false,
      });

      result.push(node);

      const fieldChildren = fields.filter((f) => f.groupId == node.id);
      const allChildren = ((node["children"] ?? []) as FieldTreeNode[])
         .concat(fieldChildren)
         .sort(ContentBrickTreeNodeTypeUtils.compareNodes);

      allChildren.forEach((child) => this.flattenDfsPreorder(child, node, depth + 1, result, fields));

      return result;
   }

   /**
    * Resets all Field Type-specific properties to their default values
    * @param field field to reset properties in
    */
   public resetFieldTypeSpecificValues(field: FieldDefinition) {
      field.fieldCalculation = undefined;
      field.list = undefined;
      field.listId = undefined;
      field.subContentBrickCode = undefined;
      field.unit = undefined;
      field.unitId = undefined;
      field.unitType = undefined;
      field.unitTypeId = undefined;
      if (ContentBrickTreeNodeTypeUtils.isValueField(field)) {
         (<DesignGuidelineValueFieldDefinition>field).value = null;
      }
   }

   /**
    * Validates Field's EvaluationType
    * @param contentBrick Content Brick definition
    * @param field Field to validate
    * @returns True if valid, error string otherwise
    */
   public validateEvaluationType(contentBrick: IContentBrickDefinition, field: FieldDefinition): true | string {
      if (contentBrick.entityStatus !== EntityStatus.Active) return true;

      if (!ContentBrickTreeNodeTypeUtils.isValueFieldGuard(field)) return true;

      if (field.evaluationType === DesignGuidelineFieldEvaluation.Disabled) return true;

      if (field.condition?.predicate?.operands?.length) return true;

      return `Condition is required`;
   }

   /**
    * Validates Field's Identifier uniqueness withing ContentBrick
    * @param contentBrick Content Brick definition
    * @param field Field to validate
    * @returns True if valid, error string otherwise
    */
   public validateIdentifierUniqueness(contentBrick: IContentBrickDefinition, field: FieldDefinition): true | string {
      if (contentBrick.entityStatus !== EntityStatus.Active) return true;

      const allFields = (contentBrick.fields ?? [])
         .concat(contentBrick.designGuidelineValueFields ?? [])
         .concat(contentBrick.designGuidelineMetadataFields ?? []);
      const allOtherIdentifiers = allFields
         .filter((cbField) => cbField.id !== field.id)
         .map((cbField) => cbField.identifier)
         .filter((identifier) => !!identifier);

      return (
         allOtherIdentifiers.findIndex((identifier) => identifier === field.identifier) < 0 ||
         "Identifier is already used for another field"
      );
   }

   // ---------- Translations ----------
   public readonly relatedTabTranslationKeyMap: Map<ContentBrickType, string> = new Map([
      [ContentBrickType.ContentBrick, "contentBrickDetail.relatedContentBricksTab"],
      [ContentBrickType.DesignGuideline, "contentBrickDetail.relatedDesignGuidelinesTab"],
      [ContentBrickType.PracticalTest, "contentBrickDetail.relatedPracticalTestsTab"],
      [ContentBrickType.GeneralTest, "contentBrickDetail.relatedGeneralTestsTab"],
      [ContentBrickType.NormsAndStandards, "contentBrickDetail.relatedNormsAndStandardsTab"],
      [ContentBrickType.MaterialCompliance, "contentBrickDetail.relatedMaterialCompliancesTab"],
      [ContentBrickType.PreShipmentInstructions, "contentBrickDetail.relatedPreShipmentInstructionsTab"],
   ]);
}

export interface IFieldCalculationReference {
   id: string;
   name: string;
   identifier: string;
}

export default new ContentBrickUtils();
