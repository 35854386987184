import { TranslationPublicModel } from "@backend/api/pmToolApi";

export function getTranslationValue(key: string, translations: TranslationPublicModel[]): string {
   if (!translations) {
      return key;
   }
   const translation = translations.find((t) => t.key == key);
   return translation?.value == null || translation?.value.length == 0 ? key : translation.value;
}

export function getTranslationValueOrDefault(
   key: string,
   translations: TranslationPublicModel[],
   defaultTranslation: string
): string {
   if (!translations) {
      return defaultTranslation;
   }
   const translation = translations.find((t) => t.key == key);
   return translation?.value ?? defaultTranslation;
}

export function getLanguageIso1(languageName: string, defaultResult = "en") {
   return (
      {
         English: "en",
         Czech: "cs",
         Chinese: "zh",
         German: "de",
      }[languageName] ?? defaultResult
   );
}
