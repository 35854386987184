<template>
   <v-snackbar v-model="show" app top color="error" timeout="-1" vertical>
      Your login token will expire in {{ timeRemaining }}s. Please refresh token.
      <template #action="{ attrs }">
         <v-btn color="error" @click="refreshToken()">Refresh token</v-btn>
      </template>
   </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AxiosKeycloak } from "../../backend/axiosKeycloak";
import { deleteCookie } from "@utils/CookieUtils";

@Component({
   name: "KeycloakLogoutTimer",
   components: {},
})
export default class KeycloakLogoutTimer extends Vue {
   show: boolean = false;

   checkInterval: any = null;

   timeRemaining: number = 300;

   mounted() {
      this.checkInterval = setInterval(() => {
         this.checkTimeout();
      }, 60000);
   }

   beforeDestroy() {
      clearInterval(this.checkInterval);
   }

   async checkTimeout() {
      let keycloak = AxiosKeycloak.getKeycloak();
      this.timeRemaining = Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000);

      console.log(this.timeRemaining);

      if (this.timeRemaining < 120)
         await keycloak
            .updateToken(300)
            .success((refreshed) => {
               if (!refreshed) {
                  console.warn("not refreshed - user will be logged out in " + this.timeRemaining + "s");
               }
            })
            .error(() => {
               console.error("not refreshed - user will be logged out in " + this.timeRemaining + "s");
            });

      this.timeRemaining = Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000);

      if (this.timeRemaining <= 0) {
         keycloak.logout();
         deleteCookie("token");
         deleteCookie("refreshToken");
      }
   }

   refreshToken() {
      this.show = false;
      let keycloak = AxiosKeycloak.getKeycloak();
      keycloak.updateToken(275);
   }
}
</script>
