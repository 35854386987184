<template>
   <v-menu v-model="isOpen" offset-y class="notification-list">
      <template #activator="{ on }" :offset-x="true" :close-on-content-click="false" :nudge-width="200">
         <v-btn icon v-on="on">
            <v-icon>mdi-earth</v-icon>
         </v-btn>
      </template>
      <v-card>
         <v-card-text>
            <v-list dense>
               <v-list-item v-for="item in languages" :key="item.id" link dense @click="languageSelected(item)">
                  <v-list-item-content>
                     <span :class="{ 'font-weight-bold': selectedLanguage && item.id === selectedLanguage.id }">
                        {{ item.name }}
                     </span>
                  </v-list-item-content>
               </v-list-item>
            </v-list>
         </v-card-text>
      </v-card>
   </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserApi, LanguagePublicModel, TranslationApi } from "@backend/api//pmToolApi";
import AuthStore from "@backend/store/authStore.ts";
import BaseResponse from "@models/BaseResponse";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import globalStore from "@backend/store/globalStore";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import TranslationCachedApi from "@backend/store/apiCache/TranslationCachedApi";
import UserCachedApi from "@backend/store/apiCache/UserCachedApi";

@Component({
   name: "LangSelector",
   components: {},
})
export default class LangSelector extends ComponentBase {
   selectedLanguage: LanguagePublicModel | null = null;

   isOpen: boolean = false;
   userId: string = "";
   loading: boolean = false;

   languages: LanguagePublicModel[] = [];

   @Watch("isOpen")
   onOpen(newVal) {
      if (newVal == true) {
         this.loadLanguagues();
      }
   }

   languageSelected(language: LanguagePublicModel) {
      this.updateUserLanguage(language);
   }

   mounted() {
      this.loadLanguagues();
      this.userId = AuthStore.getUserId()!;
      this.loadPreferredLanguage();
   }

   setPreferredLanguage(language: LanguagePublicModel | undefined) {
      if (!language) return;

      if (this.selectedLanguage != language) {
         this.selectedLanguage = language;

         if (language?.id !== globalStore.getLanguage()?.id) {
            globalStore.setLanguage(this.selectedLanguage);
            EventBus.$emit(Events.LanguageChanged);
         }
      }

      EventBus.$on(Events.LanguageChanged, () => {
         TranslationCachedApi.invalidateTranslations();
      });
   }

   //API
   async loadLanguagues() {
      this.loading = true;
      try {
         var response = await TranslationCachedApi.getActiveLanguages();
         this.languages = response;
      } catch (err) {
         let error = err as BaseResponse;
         console.log("API User lang load error:", error);
         this.notifyError(error, "load", "List of languages");
      }
      this.loading = false;
   }

   async loadPreferredLanguage(): Promise<void> {
      try {
         let preferredLanguage = await UserCachedApi.getCurrentUserPreferredLanguage();
         this.setPreferredLanguage(preferredLanguage);
      } catch (err) {
         let error = err as BaseResponse;
         console.log("API Current User preferred lang load error:", error);
         this.notifyError(error, "load", "Current User preferred Language");
         throw err;
      }
   }

   async updateUserLanguage(language: LanguagePublicModel): Promise<void> {
      try {
         let preferredLanguage = await UserApi.updatePreferredLanguage(this.userId, language);
         UserCachedApi.invalidatePreferredLanguage();
         this.setPreferredLanguage(preferredLanguage);
      } catch (error) {
         this.notifyError(error, "update", "Users preferred langauge");
      }
   }
}
</script>

<style scoped>
.selector-img {
   width: 40px;
   height: 30px;
}

.selected-img {
   width: 40px;
   height: 30px;
}

.notification-list {
   z-index: 15;
   background-color: rgb(245, 245, 245) !important;
}
</style>
