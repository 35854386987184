<template>
   <div v-if="!loading">
      <div v-if="!$vuetify.breakpoint.mobile">
         <v-autocomplete
            v-model="entityStatusVM"
            :items="AllStates"
            class="entity-status-combo float-right pt-0 pb-0"
            label="Status"
            return-object
            :background-color="entityStatusVM.color"
            filled
            hide-details
            :dark="checkDarkColor"
            :readonly="readonly"
            dense
            solo
         >
            <template #selection="data">
               {{ getEntityStatusText(data.item.value) }}
            </template>
            <template #item="data">
               <v-list-item-content>
                  <v-list-item-title class="d-flex justify-center">
                     <status
                        :pointer="true"
                        :text="getEntityStatusText(data.item.value)"
                        :color="data.item.color"
                        :propagate-click="false"
                     ></status>
                  </v-list-item-title>
               </v-list-item-content>
            </template>
         </v-autocomplete>
      </div>
      <div v-else>
         <v-select
            v-model="entityStatusVM"
            :items="AllStates"
            item-text="label"
            class="entity-status-combo float-right pt-0 pb-0"
            label="Status"
            return-object
            :background-color="entityStatusVM.color"
            filled
            hide-details
            :dark="checkDarkColor"
            :readonly="readonly"
            solo
         >
            <template #item="data">
               <v-list-item-content>
                  <v-list-item-title class="d-flex justify-center">
                     <status
                        :pointer="true"
                        :text="getEntityStatusText(data.item.value)"
                        :color="data.item.color"
                        :propagate-click="false"
                     ></status>
                  </v-list-item-title>
               </v-list-item-content>
            </template>
         </v-select>
      </div>
   </div>
</template>
<script lang="ts">
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import { Component, Prop } from "vue-property-decorator";
import Status from "@components/Shared/status.vue";
import { EntityStatus } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "EntityStatusSelect",
   components: {
      Status,
   },
})
export default class EntityStatusSelect extends ComponentBase {
   @Prop({ default: 0, required: true })
   value: number;

   @Prop({ default: true })
   isFromAdministration: boolean;

   @Prop({ default: false })
   isGetDraftOnly: boolean;

   @Prop({ default: false })
   readonly: boolean;

   originalValueState: number;
   txtColor: boolean = false;
   loading: boolean = true;

   routeNames: string[] = ["entity-status-decorator"];

   // -------- Entity status -------------
   get AllStates(): EntityStatusDecorator[] {
      if (this.isFromAdministration) {
         if (this.isGetDraftOnly) return EntityStatusDecorator.GetDraftOnly;
         else if (this.originalValueState == EntityStatus.Draft) return EntityStatusDecorator.AllItems;
         return EntityStatusDecorator.GetActiveAndInactive;
      } else return EntityStatusDecorator.AllItems;
   }

   get entityStatusVM(): EntityStatusDecorator {
      return new EntityStatusDecorator(this.value);
   }

   getEntityStatusText(value: EntityStatus): string {
      return this.translateKey(new EntityStatusDecorator(value).translationKey);
   }

   get checkDarkColor(): boolean {
      if (this.value != 0) this.txtColor = true;
      else this.txtColor = false;
      return this.txtColor;
   }

   set entityStatusVM(decorator: EntityStatusDecorator) {
      this.updateValue(decorator.value);
   }

   async mounted() {
      this.loading = true;
      this.originalValueState = this.value;
      await this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
      this.loading = false;
   }

   // Updates the 'v-model' value by emiting updated value via 'input' event
   updateValue(value: number) {
      this.$emit("input", value);
   }
}
</script>
<style scoped>
.entity-status-combo {
   width: 15em;
}

.float-right {
   margin-left: auto;
   flex-grow: 0;
}
</style>
