import moment from "moment";
import ValidationRules from "@models/shared/ValidationRules";
import { ContentBrickFieldType } from "@backend/api/pmToolApi";

export class DateTimeFormatInfo {
   parse: string[];
   icon: string;
   display: string;
   placeholder: string;

   public constructor(init?: Partial<DateTimeFormatInfo>) {
      Object.assign(this, init);
   }
}

class DateTimeUtils {
   public readonly formats = {
      date: new DateTimeFormatInfo({
         parse: [
            ValidationRules.isoFormatDate /*just date*/,
            ValidationRules.isoFormatDateTime /*or datetime with zero time part*/,
         ],
         icon: "mdi-calendar",
         display: "l",
         placeholder: moment.localeData().longDateFormat("l"),
      }),
      time: new DateTimeFormatInfo({
         parse: [ValidationRules.isoFormatTime],
         icon: "mdi-clock-outline",
         display: "LTS",
         placeholder: moment.localeData().longDateFormat("LTS"),
      }),
      dateTime: new DateTimeFormatInfo({
         parse: ValidationRules.isoFormatDateTimeFractions,
         icon: "mdi-calendar-clock",
         display: "l LTS",
         placeholder: `${moment.localeData().longDateFormat("l")} ${moment.localeData().longDateFormat("LTS")}`,
      }),
   };

   public getFormat(hasDate: boolean, hasTime: boolean): DateTimeFormatInfo {
      return hasDate ? (hasTime ? this.formats.dateTime : this.formats.date) : this.formats.time;
   }

   public getFieldFormat(fieldType: ContentBrickFieldType): DateTimeFormatInfo {
      if (fieldType === ContentBrickFieldType.Time) return this.formats.time;
      if (fieldType === ContentBrickFieldType.Date) return this.formats.date;
      if (fieldType === ContentBrickFieldType.DateTime) return this.formats.dateTime;

      return this.formats.dateTime; //fallback value
   }

   public formatDateTime(dateTime: string | null, format: DateTimeFormatInfo): string | null {
      if (dateTime === null) return null;

      return this.getDateTimeLocalized(moment(dateTime, format.parse, true), format);
   }

   public getDateTimeLocalized(dateTime: moment.Moment | undefined | null, format: DateTimeFormatInfo): string | null {
      return dateTime?.format(format.display) ?? null;
   }

   private readonly Saturday = 6;
   private readonly Sunday = 0;

   public addBusinessDays(originalDate: moment.Moment, numDaysToAdd: number): moement.Moment {
      if (!originalDate || !numDaysToAdd) return originalDate;
      let daysRemaining = numDaysToAdd;
      const newDate = originalDate.clone();

      while (daysRemaining > 0) {
         newDate.add(1, "days");
         if (newDate.day() !== this.Saturday && newDate.day() !== this.Sunday) {
            daysRemaining--;
         }
      }

      return newDate;
   }

   public subtractBusinessDays(originalDate: moment.Moment, numDaysToSubtract: number): moment.Moment {
      if (!originalDate || !numDaysToSubtract) return originalDate;
      let daysRemaining = numDaysToSubtract;
      const newDate = originalDate.clone();

      while (daysRemaining > 0) {
         newDate.subtract(1, "days");
         if (newDate.day() !== this.Saturday && newDate.day() !== this.Sunday) {
            daysRemaining--;
         }
      }

      return newDate;
   }

   public getBusinessDaysBetween(firstDate: moment.Moment, secondDate: moment.Moment): number {
      const startDate = firstDate.isSameOrBefore(secondDate) ? firstDate.clone() : secondDate.clone();
      const endDate = firstDate.isSameOrBefore(secondDate) ? secondDate.clone() : firstDate.clone();
      let businessDays = 0;

      while (startDate.isBefore(endDate)) {
         if (startDate.day() !== this.Saturday && startDate.day() !== this.Sunday) {
            businessDays++;
         }
         startDate.add(1, "days");
      }
      return businessDays;
   }
}

export default new DateTimeUtils();
