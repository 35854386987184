<template>
   <v-checkbox
      ref="switchInput"
      :value="valueInternal"
      :input-value="valueInternal"
      :class="classes"
      :disabled="disabled"
      :true-value="trueValue"
      :false-value="falseValue"
      :indeterminate="isIndeterminate"
      :rules="rules"
      color="red"
      :on-icon="icons.on"
      :off-icon="icons.off"
      :indeterminate-icon="icons.indeterminate"
      v-bind="$attrs"
      @change="onChange"
   >
      <template v-for="(index, name) in $slots" #[name]>
         <slot :name="name" />
      </template>
   </v-checkbox>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { BooleanFieldLayout } from "@backend/api/pmToolApi";

class CheckboxIconSet {
   on: string;
   off: string;
   indeterminate: string;

   static get Checkbox(): CheckboxIconSet {
      return {
         on: "$checkboxOn",
         off: "$checkboxOff",
         indeterminate: "$checkboxIndeterminate",
      };
   }

   static get Switch(): CheckboxIconSet {
      return {
         on: "$switchTrue",
         off: "$switchFalse",
         indeterminate: "$switchIndeterminate",
      };
   }
}

/**
 * Switch component which supports truthy, falsy and indetermined (null) states.
 * Based on v-checkbox.
 */
@Component({
   name: "ThreeStateSwitch",
   components: {},
})
export default class ThreeStateSwitch extends Vue {
   @Prop()
   value: any;

   @Prop()
   rules: any | undefined;

   //---------------- v-checkbox props ----------------
   @Prop({ default: undefined })
   inputValue: any;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: true })
   trueValue: any;

   @Prop({ default: false })
   falseValue: any;
   //-------------------------------------------------

   /**
    * If true, allow user to set indetermined value (null, in between false and true states),
    * otherwise, user can only switch between false and true values (null can be only default)
    */
   @Prop({ default: false })
   allowSetIndeterminate: boolean;

   /**
    * If true, component should use input-value prop instead of v-model value,
    * otherwise, component uses v-model value
    */
   @Prop({ default: false })
   useInputValue: boolean;

   /**
    * Presentation/icons used, always fallback to Switch visuals
    */
   @Prop({ default: BooleanFieldLayout.Switch })
   layout: BooleanFieldLayout;

   get valueInternal(): any {
      return this.useInputValue ? this.inputValue : this.value;
   }

   get isIndeterminate(): boolean {
      return this.valueInternal === null || this.valueInternal === undefined;
   }

   get classes(): string {
      if (this.layout === BooleanFieldLayout.Checkbox) {
         return this.disabled ? "three-state-checkbox three-state-switch-disabled" : "three-state-checkbox";
      }
      return this.disabled ? "three-state-switch three-state-switch-disabled" : "three-state-switch";
   }

   /**
    * Presentation/icons used, always fallback to Switch visuals
    */
   get icons(): CheckboxIconSet {
      return this.layout === BooleanFieldLayout.Checkbox ? CheckboxIconSet.Checkbox : CheckboxIconSet.Switch;
   }

   onChange(value: any) {
      if (this.allowSetIndeterminate) {
         // three state handling: null -> true -> false -> null...
         value =
            this.valueInternal === this.falseValue
               ? null // false -> null
               : this.valueInternal === this.trueValue
                 ? this.falseValue // true -> false
                 : this.trueValue; // null -> true
      } else {
         // two state handling: null -> true -> false -> true...
         value =
            this.valueInternal === this.falseValue
               ? this.trueValue // false -> true
               : this.valueInternal === this.trueValue
                 ? this.falseValue // true -> false
                 : this.trueValue; // null -> true
      }
      // emit events
      this.$emit("input", value);
      this.$emit("change", value);
   }

   validate() {
      this.$refs?.["switchInput"]?.validate();
   }
}
</script>
<style scoped lang="scss">
.three-state-switch {
   // disabled -> faded colors as in v-switch
   &-disabled {
      opacity: 0.6;
   }
}
</style>
<style lang="scss">
.v-input--checkbox.three-state-switch {
   // fix icon size to match v-switch
   .v-input--selection-controls__input {
      width: 46px;
      margin-right: 4px;
      .v-icon__component {
         height: initial;
         width: initial;
      }
   }

   // fix hover circle/ripple
   .v-input--selection-controls__ripple {
      transition: none;
   }

   &.v-input--is-dirty .v-input--selection-controls__ripple {
      -webkit-transform: translate(22px);
      transform: translate(22px);
   }

   &.v-input--indeterminate .v-input--selection-controls__ripple {
      -webkit-transform: translate(11px);
      transform: translate(11px);
   }
}
</style>
