import { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { AxiosKeycloak } from "../axiosKeycloak";
import qs from "query-string";
import BaseResponse from "../../models/BaseResponse";
import PatchDto from "../../models/shared/PatchDto";
import { method } from "./enums/method";
import authStore from "../store/authStore";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { ApiOptions } from "@backend/api/apiOptions";

function getAbsoluteUrl(relativeUrl: string): string {
   return AxiosKeycloak.axios.defaults.baseURL + relativeUrl;
}

function logResponseError(
   relativeUrl: string,
   method: method | string,
   responseError: any,
   apiOptions?: ApiOptions
): void {
   console.error(`API [${method}] ERROR ('${getAbsoluteUrl(relativeUrl)}')`, responseError);
   if (apiOptions?.showErrorNotification !== false) {
      EventBus.$emit(Events.DisplayToast, {
         color: "error",
         text: "API ERROR: " + responseError,
      });
   }
   EventBus.$emit(Events.AppInsightsTrack, {
      event: `API [${method}] ERROR ('${getAbsoluteUrl(relativeUrl)}')`,
      value: responseError.toString(),
   });
}

async function get<TResponse>(
   url: string,
   addAuthHeaders: boolean,
   noCache: boolean,
   apiOptions?: ApiOptions
): Promise<AxiosResponse<TResponse>> {
   const headers: any = {};

   if (noCache) {
      headers["Cache-Control"] = "no-cache";
   }

   try {
      const axiosResponse = await AxiosKeycloak.axios.get<TResponse>(url, {
         headers,
      });
      return axiosResponse;
   } catch (e) {
      throw handleError(url, method.get, e, apiOptions);
   }
}

async function head(url: string, apiOptions?: ApiOptions): Promise<AxiosResponse<any>> {
   try {
      return AxiosKeycloak.axios.head(url);
   } catch (e) {
      logResponseError(url, method.head, e, apiOptions);
      throw e;
   }
}

async function post<TResponse>(
   url: string,
   addAuthHeaders: boolean,
   data?: any,
   config?: AxiosRequestConfig,
   apiOptions?: ApiOptions
): Promise<AxiosResponse<TResponse>> {
   const rqstConf: AxiosRequestConfig = config ?? {};

   if (data) {
      if (typeof data !== "object") {
         rqstConf.headers = { "Content-Type": "application/json" };
      }

      // wrap string data in quotes
      // if (typeof data === "string" && data.length > 0 && data[0] !== '"') {
      //   console.log("STRING");
      //   data = `"${data}"`;
      // }
   }

   try {
      const axiosResponse = await AxiosKeycloak.axios.post<TResponse>(url, data, rqstConf);
      return axiosResponse;
   } catch (e) {
      throw handleError(url, method.post, e, apiOptions);
   }
}

async function put<TResponse>(url: string, data: any, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
   const rqstConf: AxiosRequestConfig = {};

   if (typeof data !== "object") {
      rqstConf.headers = { "Content-Type": "application/json" };
   }

   try {
      const axiosResponse = await AxiosKeycloak.axios.put<TResponse>(url, data, rqstConf);
      return axiosResponse;
   } catch (e) {
      throw handleError(url, method.put, e, apiOptions);
   }
}

async function del<TResponse>(url: string, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
   try {
      const axiosResponse = await AxiosKeycloak.axios.delete(url);
      return axiosResponse;
   } catch (e) {
      throw handleError(url, method.delete, e, apiOptions);
   }
}

async function patch<TResponse>(url: string, data: any, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
   const rqstConf: AxiosRequestConfig = {};

   if (typeof data !== "object") {
      rqstConf.headers = { "Content-Type": "application/json" };
   }

   try {
      const axiosResponse = await AxiosKeycloak.axios.patch<TResponse>(url, data, rqstConf);
      return axiosResponse;
   } catch (e) {
      throw handleError(url, method.patch, e, apiOptions);
   }
}

// TODO file type
async function upload<TResponse>(url: string, file: any, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
   try {
      const axiosResponse = await AxiosKeycloak.axios.post<TResponse>(url, file, {
         headers: { "Content-Type": "multipart/form-data" },
      });
      return axiosResponse;
   } catch (e) {
      throw handleError(url, "FILE UPLOAD", e, apiOptions);
   }
}

function handleAxiosResponse<TResponse>(axiosResponse: AxiosResponse<TResponse>): BaseResponse<TResponse> {
   const response: BaseResponse<TResponse> = {
      data: axiosResponse.data,
      errors: null,
      message: axiosResponse.statusText,
      status: axiosResponse.status,
      success: true,
   };

   return response;
}

function handleError<TResponse>(
   url: string,
   method: string,
   error: any,
   apiOptions?: ApiOptions
): BaseResponse<TResponse> {
   logResponseError(url, method, error, apiOptions);
   if (error.response) {
      return handleAxiosError<TResponse>(error);
   } else {
      return handleConnectionError<TResponse>();
   }
}

function handleAxiosError<TResponse>(axiosError: AxiosError): BaseResponse<TResponse> {
   const response: BaseResponse = {
      success: false,
      message: axiosError.response.data.message,
      data: axiosError.response.data,
      errors: axiosError.response.data.errors,
      status: axiosError.response.status,
   };

   return response;
}

function handleConnectionError<TResponse>(): BaseResponse<TResponse> {
   const response: BaseResponse = {
      success: false,
      message: "Connection error, please try again later",
      data: null,
      errors: null,
      status: null,
   };

   return response;
}

class api {
   public get<TResponse>(url: string, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
      return get(url, true, false, apiOptions);
   }

   public getNoAuth<TResponse>(url: string, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
      return get(url, false, false, apiOptions);
   }

   public getNoCache<TResponse>(url: string, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
      return get(url, true, true, apiOptions);
   }

   public post<TResponse>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig,
      apiOptions?: ApiOptions
   ): Promise<AxiosResponse<TResponse>> {
      return post(url, true, data, config, apiOptions);
   }

   public postNoAuth<TResponse>(url: string, data?: any, apiOptions?: ApiOptions): Promise<AxiosResponse<TResponse>> {
      return post(url, false, data, undefined, apiOptions);
   }

   public put = put;

   public patch = patch;

   public delete = del;

   public upload = upload;

   public head = head;
}

export { getAbsoluteUrl };

export default new api();
