<template>
   <v-dialog v-model="showDialog" width="500" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>Confirm</span>
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col>
                  <p>
                     Do you really want to delete "
                     <span class="font-weight-medium">{{ itemName }}</span>
                     "?
                  </p>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
               v-shortkey.once="['esc']"
               class="red--text"
               outlined
               ui-test-data="delete-cancel-btn"
               @click="cancelClick"
               @shortkey="cancelClick"
            >
               Cancel
            </v-btn>
            <v-btn class="error" text ui-test-data="delete-confirm-btn" @click="deleteClick">Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
   name: "ConfirmDeleteDialogAsync",
   components: {},
})
export default class ConfirmDeleteDialogAsync extends Vue {
   showDialog: boolean = false;
   itemName: string = "item";
   resolve: (result: boolean) => void;

   async showDialogAsync(itemName: string): Promise<boolean> {
      return new Promise<boolean>((resolve) => {
         this.itemName = itemName ?? "item";
         this.showDialog = true;
         this.resolve = resolve;
      });
   }

   cancelClick() {
      this.showDialog = false;
      this.resolve && this.resolve(false);
   }

   deleteClick() {
      this.showDialog = false;
      this.resolve && this.resolve(true);
   }
}
</script>
