<template>
   <v-tooltip top>
      <template #activator="{ on, attrs }">
         <v-btn icon v-bind="attrs" :to="newItemRoute" :disabled="isDisabled" v-on="on">
            <v-icon dense color="success">mdi-plus</v-icon>
         </v-btn>
      </template>
      <span>{{ translateKey("detailView.createNewTooltip", translations) }} {{ tooltipTitle }}</span>
   </v-tooltip>
</template>

<script lang="ts">
import { TranslationPublicModel } from "@backend/api/pmToolApi";
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "CreateNewItemBtn",
   components: {},
})
export default class CreateNewItemBtn extends ComponentBase {
   isDisabled: boolean = false;

   @Prop({ default: null })
   newItemRoute: string;

   @Prop({ default: null })
   tooltipTitle: string;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   mounted() {
      if (this.$router.currentRoute.fullPath == this.newItemRoute) {
         this.isDisabled = true;
      }
   }
}
</script>
