<template>
   <v-dialog v-model="showDialog" width="600" persistent>
      <template #activator="{ on, attrs }">
         <slot name="activator" :on="on" :attrs="attrs">
            <v-btn v-bind="attrs" class="ml-2" v-on="on">
               <v-icon>mdi-table-edit</v-icon>
               {{ translateKey("columnSelector.chooseColumnsLabel") }}
            </v-btn>
         </slot>
      </template>
      <v-card>
         <v-card-title primary-title>
            <span>
               {{ translateKey("columnSelector.chooseColumnsLabel") }}
            </span>
            <v-spacer></v-spacer>
            <v-btn class="red--text" icon @click="close()">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-container>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="searchQuery"
                        append-icon="mdi-magnify"
                        color="red darken-2"
                        :label="translateKey('label.search')"
                        single-line
                        hide-details
                        autofocus
                        @input="search()"
                     />
                  </v-col>
               </v-row>
               <v-row no-gutters>
                  <v-col v-for="item in filteredHeaders" :key="item.value" cols="3">
                     <v-checkbox v-model="selected" :value="item.value" :disabled="isDisabled(item.value)">
                        <template #label>
                           <div class="d-flex align-center">
                              {{ item.text }}
                           </div>
                        </template>
                     </v-checkbox>
                  </v-col>
               </v-row>
            </v-container>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" text @click="save()">
               {{ translateKey("button.save") }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { EntityType } from "@backend/api/pmToolApi";
import { clone, debounce } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import gridOperationStore, { GridName } from "@backend/store/gridOperationsStore";
import { watch } from "fs";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component
export default class ColumnSelection extends ComponentBase {
   showDialog: boolean = false;

   @Prop({ required: true })
   headers: any[];

   @Prop({ required: true })
   defaultHeaders: string[];

   @Prop({ default: null })
   disabledHeaders: string[] | null;

   filteredHeaders: any[] = [];

   @Prop({ required: true })
   entityType: EntityType | GridName;

   selected: string[] = [];
   originalValues: string[] = [];

   searchQuery: string | null = null;

   isDisabled(columnKey: string): boolean {
      if (!this.disabledHeaders) return false;

      return this.disabledHeaders.some((dh) => dh === columnKey);
   }

   save() {
      gridOperationStore.setSelectedColumns(this.entityType, this.selected);
      this.$emit("selected-columns", this.selected);
      this.close();
   }

   @Watch("showDialog")
   onChange() {
      this.selected = gridOperationStore.getSelectedColumns(this.entityType) ?? this.defaultHeaders;
   }

   @Watch("headers")
   headersChange() {
      this.filteredHeaders = this.headers;
   }

   close() {
      this.showDialog = false;
      this.searchQuery = null;
      this.filteredHeaders = this.getSortedHeaders(this.headers);
   }

   search() {
      if (this.searchQuery) {
         this.filteredHeaders = this.getSortedHeaders(
            this.headers.filter((x) => x.text.toLowerCase().includes(this.searchQuery!.toLowerCase()))
         );
      } else {
         this.filteredHeaders = this.getSortedHeaders(this.headers);
      }
   }

   mounted() {
      this.loadRouteTranslations("column-selector");
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations("column-selector"));
      this.selected = gridOperationStore.getSelectedColumns(this.entityType) ?? this.defaultHeaders;
      this.filteredHeaders = /*do not overwrite source!*/ this.getSortedHeaders(this.headers);
      this.searchQuery = null;
   }

   getSortedHeaders(headers: any[]) {
      return [...headers].sort((a, b) => a.text.localeCompare(b.text));
   }
}
</script>

<style scoped></style>
