import {
   ItemReference,
   UnitTypeApi,
   QueryOptionsOfUnitTypeFilterOptions,
   UnitTypeFilterOptions,
   EntityStatus,
} from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";

class UnitTypeCachedApi extends CachedApiBase {
   invalidateAll() {
      this.invalidateItem("UnitTypeApi.getAllUnitTypes");
   }

   /**
    * Returns all active Unit Types
    * @return List of all Unit Types references
    */
   async getActiveUnitTypes(): Promise<ItemReference[]> {
      return await this.cacheEndpointAsync<ItemReference>(
         "UnitTypeApi.getAllUnitTypes",
         async () => {
            const queryOptions = new QueryOptionsOfUnitTypeFilterOptions();
            queryOptions.filter = UnitTypeFilterOptions.fromJS({
               entityStatus: EntityStatus.Active,
            });
            const res = await UnitTypeApi.getAllUnitTypes(queryOptions);
            return res.documents ?? [];
         },
         ItemReference.fromJS
      );
   }

   private static _instance: UnitTypeCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): UnitTypeCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default UnitTypeCachedApi.instance;
