<template>
   <div>
      <!-- Multiple selectable (with pills) autocomplete -->
      <template v-if="showOnlyButton">
         <v-btn tile color="error" elevation="2" icon ui-test-data="upload-btn" @click="onOpenAddDialogClick">
            <v-icon>{{ icon }}</v-icon>
         </v-btn>
      </template>
      <template v-else>
         <v-autocomplete
            v-if="!singleSelect"
            ref="autoComplete"
            :key="autocompleteReloadKey"
            v-model="value_deepCopy"
            :label="selectLabel"
            :items="entityItems"
            :search-input.sync="search"
            :loading="loading"
            item-text="displayText"
            return-object
            :multiple="true"
            hide-selected
            :hide-no-data="hideNoData"
            :readonly="disabled"
            :rules="rules"
            color="error"
            ui-test-data="autocomplete-input"
            @click="onAutocompleteClicked"
            @focus="onAutocompleteFocused"
            @dblclick="onOpenAddDialogClick"
            @keyup.enter="createNewEntityAndSet()"
         >
            <template #item="data">
               <slot name="autocomplete-item" v-bind="data" />
            </template>
            <template #selection="{ item }">
               <v-tooltip top :disabled="readOnlyItemReason?.(item) === undefined">
                  <template #activator="{ on, attrs }">
                     <v-chip
                        v-bind="attrs"
                        :key="item.id"
                        :close="canRemoveItem(item)"
                        class="ma-1"
                        v-on="on"
                        @click:close="removeItem(item)"
                     >
                        {{ item.displayText }}
                     </v-chip>
                  </template>
                  <span>{{ readOnlyItemReason?.(item) }}</span>
               </v-tooltip>
            </template>
            <template slot="append-outer">
               <v-btn tile color="error" elevation="2" icon ui-test-data="upload-btn" @click="onOpenAddDialogClick">
                  <v-icon>{{ icon }}</v-icon>
               </v-btn>
            </template>
            <template v-if="createApiEndpoint && search && search.length > 1" slot="no-data">
               <v-col
                  class="pb-0 cursor-pointer"
                  @keyup.enter="createNewEntityAndSet()"
                  @click="createNewEntityAndSet()"
               >
                  Add new {{ entity.toLowerCase() }}:
                  <v-chip>{{ search }}</v-chip>
               </v-col>
            </template>
         </v-autocomplete>
         <!-- Single selectable (with text value) autocomplete -->
         <v-autocomplete
            v-else
            ref="autoComplete"
            :key="autocompleteReloadKey"
            v-model="value_deepCopy"
            :label="selectLabel"
            :items="entityItems"
            :search-input.sync="search"
            :loading="loading"
            item-text="displayText"
            return-object
            :multiple="false"
            :clearable="allowNone && !disabled"
            :hide-no-data="hideNoData"
            :readonly="disabled"
            :rules="rules"
            color="error"
            :hide-details="hideDetails"
            ui-test-data="entity-details-input"
            @click="onAutocompleteClicked"
            @focus="onAutocompleteFocused"
            @dblclick="onOpenAddDialogClick"
         >
            <template slot="append-outer">
               <v-btn
                  class="add-reference-textfield-append"
                  tile
                  color="error"
                  elevation="2"
                  icon
                  ui-test-data="open-list-btn"
                  @click="onOpenAddDialogClick"
               >
                  <v-icon>{{ icon }}</v-icon>
               </v-btn>
            </template>
            <template #item="data">
               <slot name="autocomplete-item" v-bind="data" />
            </template>
         </v-autocomplete>
      </template>
      <add-reference-selection-dialog
         :filter="value_deepCopy"
         :entity="entity"
         :apiEndpoint="apiEndpoint"
         :newItemRoute="newItemRoute"
         :itemDetailRoute="itemDetailRoute"
         :singleSelect="singleSelect"
         :showDomainSelect="showDomainSelect"
         :headers="headers"
         :isAdd="false"
         :allowNone="allowNone"
         :showDialog="isAddReferenceSelectionDialogShown"
         :isServerSide="isServerSide"
         :disabled="disabled"
         :sortBy.sync="sortByInternal"
         :sortDesc.sync="sortDescInternal"
         :show-expand="showExpand"
         :read-only-item-reason="readOnlyItemReason"
         @addReferences="addReferences"
         @hideDialog="hideAddReferenceSelectionDialog"
      >
         <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot v-if="slot !== 'autocomplete-item'" :name="slot" v-bind="scope" />
         </template>
      </add-reference-selection-dialog>
   </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import $ from "jquery";
import BaseResponse from "@models/BaseResponse";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";
import { ItemReference } from "@backend/api/pmToolApi";
import ViewItem from "@models/view/ViewItem";
import AddReferenceSelectionDialog from "@components/Shared/add-reference-selection-dialog.vue";
import pluralize from "pluralize";
import globalStore from "@backend/store/globalStore";
import _ from "lodash";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { RoutePathWithoutParams } from "@root/routes";
import { ValidationRule } from "@models/shared/ValidationRules";

const DisableSearch = {
   bind(el, binding, vnode) {
      const isDisabled = binding.expression === undefined || !!binding.expression;

      if (el) {
         $(el).find("input").attr("readonly", isDisabled);
      }
   },
};

@Component({
   name: "AddReferenceDialog",
   components: {
      AddReferenceSelectionDialog,
      AddReferenceDialogHeader,
   },
   directives: {
      DisableSearch,
   },
})
export default class AddReferenceDialog extends Vue {
   loadingSearch: boolean = false;
   loadingItemReferences: boolean = false;
   allItems: ItemReference[] = [];
   search: string | null = null;

   // This is a hack to avoid chips in autocomplete not matching the data. TODO: solve properly as part of #35866
   autocompleteReloadKey: number = 0;

   @Prop({ required: true })
   value: ItemReference[] | ItemReference | null | undefined;

   @Prop({ default: "Reference" })
   entity: string;

   @Prop({ default: null })
   newItemRoute: RoutePathWithoutParams;

   @Prop({ default: false })
   showExpand: boolean;

   /**
    * API endpoint which is used to get and select references via popup dialog with table
    * (may be serverside paginated -> returning paged data, or non-serverside paginated -> returning all references at once)
    */
   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   apiEndpoint: (domain?: number) => Promise<ItemReference[]>;

   /**
    * API endpoint which is used to add references directly via autocomplete lookup search field
    * (should return all references at once -> in order to correctly filter items, unless serverside search functionality is implemented)
    */
   @Prop({
      default: null,
   })
   apiEndpointAll: (domain?: number) => Promise<ItemReference[]> | null;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: false })
   singleSelect: boolean;

   /**
    * Whether or not the current component should perform server side paging on the provided 'apiEndpoint'
    */
   @Prop({ default: false })
   isServerSide: boolean;

   @Prop({ default: true })
   allowNone: boolean;

   @Prop({ default: false })
   hideDetails: boolean;

   @Prop({ default: false })
   singleLine: boolean;

   @Prop()
   headers?: ViewItem[];

   @Prop({ default: () => [] })
   rules: ValidationRule<ItemReference | null | undefined>[];

   @Prop({ default: true })
   hideNoData: boolean;

   @Prop({ default: null })
   createApiEndpoint: (value: string) => Promise<ItemReference> | null;

   @Prop({ default: null })
   itemDetailRoute: RoutePathWithoutParams;

   @Prop({ default: "mdi-upload" })
   icon: string;

   @Prop({ default: false })
   showOnlyButton;
   /**
    * Whether or not to show domain selection.
    * When set to true, "apiEndpoint" is expected to have a "domain" parameter, in order to load references tied to the given domain
    */
   @Prop({ default: false })
   showDomainSelect: boolean;

   @Prop({ default: true })
   showLabelOnInput: boolean;

   @Prop({ default: undefined })
   sortBy?: string | [];

   @Prop({ default: undefined })
   sortDesc?: boolean | [];

   /**
    * Should return a reason why the item is readonly or `undefined` if it is not.
    */
   @Prop({ default: undefined })
   readOnlyItemReason?: (x: ItemReference) => string | undefined;

   @Watch("search")
   async onValueChanged() {
      if (!this.isAutocompleteMenuAllowed) this.allowAutocompleteMenu(); // undo hiding autocomplete menu
      await this.onSearchChangedDebounced();
   }

   async onSearchChanged() {
      if (
         this.search != null &&
         this.search.length > 1 &&
         ((this.singleSelect && this.search != this.textFieldValue) || !this.singleSelect)
      ) {
         this.loadingSearch = true;

         await this.loadAllItems();

         this.loadingSearch = false;
      }
   }

   // debounced handling of search changed -> as user is typing, respond only once
   onSearchChangedDebounced: Function = _.debounce(this.onSearchChanged, 300);

   /**
    * Reset autocomplete search text input and hide menu after selecting item
    */
   @Watch("value_deepCopy")
   onValueDeepCopyChanged() {
      if (this.$refs.autoComplete) {
         this.$refs.autoComplete.internalSearch = "";
         this.$refs.autoComplete.isMenuActive = false; // when item selected, menu should be closed
         this.autocompleteReloadKey++;
      }
   }

   get loading(): boolean {
      return this.loadingItemReferences;
   }

   get value_deepCopy(): ItemReference[] | ItemReference | null {
      if (this.value instanceof ItemReference) {
         return new ItemReference({ ...this.value });
      } else if (Array.isArray(this.value)) {
         return this.value.map((item) => new ItemReference({ ...item }));
      }

      return null;
   }

   set value_deepCopy(value: ItemReference[] | ItemReference | null) {
      // When the underlying component is cleared and we are in this.allowNone == false mode, we stop propagating the update
      if (this.singleSelect && !this.allowNone && value == null) return;
      if (!this.singleSelect && !this.allowNone && Array.isArray(value) && value.length < 1) return;

      if (Array.isArray(value)) {
         value.sort(function (a: ItemReference, b: ItemReference) {
            return a?.displayText?.toLowerCase() > b?.displayText?.toLowerCase() ? 1 : -1;
         });
      }
      this.$emit("input", value);
   }

   canRemoveItem(item: ItemReference) {
      return !this.disabled && this.readOnlyItemReason?.(item) === undefined;
   }

   removeItem(item: ItemReference) {
      if (!this.canRemoveItem(item)) {
         throw new Error("Invariant error. Cannot remove readonly item.");
      }
      if (Array.isArray(this.value_deepCopy)) {
         this.value_deepCopy = this.value_deepCopy.filter((x) => x.id !== item.id);
      } else if (this.value_deepCopy?.id === item.id) {
         this.value_deepCopy = null;
      }
   }

   get textFieldValue(): string | null {
      if (this.singleSelect && this.value_deepCopy && this.value_deepCopy instanceof ItemReference) {
         return this.value_deepCopy.displayText ?? null;
      }

      return null;
   }

   set textFieldValue(value: string | null) {
      if (!value) {
         this.value_deepCopy = null;
      }
   }

   get selectLabel(): string | undefined {
      return this.showLabelOnInput ? (this.singleSelect ? this.entity : this.entityPlural) : undefined;
   }

   @Prop({ default: true })
   pluralize: boolean;

   get entityPlural(): string {
      return this.pluralize ? pluralize(this.entity) : this.entity;
   }

   get entityItems(): ItemReference[] {
      if (this.allItems != null && this.allItems.length > 0) {
         return Array.isArray(this.allItems) ? this.allItems : [this.allItems];
      }

      if (!this.value_deepCopy) {
         return [];
      }

      return Array.isArray(this.value_deepCopy) ? this.value_deepCopy : [this.value_deepCopy];
   }

   // -------- Add dialog -------------
   isAddReferenceSelectionDialogShown: boolean = false;
   newEntityCreationInProgress: boolean = false;

   showAddReferenceSelectionDialog() {
      this.isAddReferenceSelectionDialogShown = true;
      setTimeout(() => {
         if (this.$refs.autoComplete) {
            this.$refs.autoComplete.isMenuActive = false;
         }
      }, 200);
   }

   hideAddReferenceSelectionDialog() {
      this.isAddReferenceSelectionDialogShown = false;
   }

   onOpenAddDialogClick() {
      this.showAddReferenceSelectionDialog();
   }

   addReferences(selectedReferences: ItemReference[]) {
      var references: ItemReference | ItemReference[] | null;

      if (this.singleSelect) {
         references = selectedReferences[0] ? new ItemReference({ ...selectedReferences[0] }) : null;
      } else {
         references = selectedReferences;
      }

      this.value_deepCopy = references;
      this.hideAddReferenceSelectionDialog();
   }

   async createNewEntityAndSet() {
      if (this.newEntityCreationInProgress) {
         return; // Prevent duplicate API requests
      }

      let trimmedSearch = this.search?.trim();

      if (!trimmedSearch || trimmedSearch.length == 0) {
         return; // If input is empty or already existing entity was selected from the list by 'enter' keyboard button.
      }

      if (trimmedSearch.length <= 1) {
         EventBus.$emit(Events.DisplayToast, { color: "error", text: "Minimal length is 2 characters" });
         return;
      }

      this.newEntityCreationInProgress = true;
      setTimeout(async () => {
         let matchingItems = this.entityItems.filter(
            (obj) => obj.displayText?.toLowerCase() == trimmedSearch?.toLowerCase()
         );
         if (matchingItems.length > 0) {
            if (Array.isArray(this.value)) {
               if (!this.value.some((obj) => obj.displayText === matchingItems[0].displayText)) {
                  this.value.push(matchingItems[0]);
               } else {
                  console.warn("already exists");
               }
            } else {
               this.value = matchingItems[0];
            }
         } else if (this.createApiEndpoint) {
            let res = await this.createApiEndpoint(trimmedSearch);
            if (res) {
               if (Array.isArray(this.value)) {
                  this.value.push(res);
               } else {
                  this.value = res;
               }
               this.$emit("input", this.value);
               await this.loadAllItems();
            }
         }
         this.newEntityCreationInProgress = false;
      }, 500);
   }

   // -------- Sort ------------
   get sortByInternal(): string | [] | undefined {
      return this.sortBy;
   }

   set sortByInternal(value: string | [] | undefined) {
      this.$emit("update:sortBy", value);
   }

   get sortDescInternal(): boolean | [] | undefined {
      return this.sortDesc;
   }

   set sortDescInternal(value: boolean | [] | undefined) {
      this.$emit("update:sortDesc", value);
   }

   //--------- Autocomplete menu hiding ---------
   /**
    * Whether or not to hide/disallow the autocomplete's menu with suggestions
    * (should be only shown/allowed when currently searching)
    */
   isAutocompleteMenuAllowed: boolean = true;

   onAutocompleteFocused() {
      this.disallowAutocompleteMenu();
   }

   onAutocompleteClicked() {
      this.disallowAutocompleteMenu();
   }

   disallowAutocompleteMenu() {
      this.isAutocompleteMenuAllowed = false;
   }

   allowAutocompleteMenu() {
      // NOTE: only undo of forced hiding of the menu
      this.isAutocompleteMenuAllowed = true;
   }

   registerAutocompleteMenuWatch() {
      this.$watch("$refs.autoComplete.isMenuActive", (newValue: boolean, oldValue: boolean) => {
         if (oldValue === false && newValue === true) {
            // when autocomplete component tries to show the menu
            if (!this.isAutocompleteMenuAllowed) {
               // and if the flag to show/allow the menu is set to 'false'
               this.$refs.autoComplete.isMenuActive = false; // discard showing of menu
            }
         }
      });
   }

   mounted() {
      this.registerAutocompleteMenuWatch();
   }

   // -------- API -------------
   async loadAllItems(): Promise<void> {
      // Items have already been requested
      if (this.loadingItemReferences) {
         return;
      }

      this.loadingItemReferences = true;

      try {
         var endpoint = this.isServerSide ? this.apiEndpointAll : this.apiEndpoint;
         if (!endpoint) {
            throw `Endpoint function is required in order to load references on add-reference-dialog: Entity: '${this.entity}', ServerSide: '${this.isServerSide}'`;
         }

         // Call the API
         let allItems;
         if (this.showDomainSelect) {
            allItems = await endpoint(globalStore.getDomain());
         } else {
            allItems = await endpoint();
         }

         // Process/Save data etc.
         this.allItems = allItems;
      } catch (e) {
         const error = e as BaseResponse;
         console.log(`API ${this.entity} load error:`, error);
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to load ${this.entity}: ${error?.message}`,
         });
      }
      this.loadingItemReferences = false;
   }
}
</script>
<style lang="scss" scoped>
/* hide arrow in chips input - there wont be any dropdowns */
::v-deep .hide-dropdown .mdi-menu-down {
   display: none !important;
}
.cursor-pointer {
   cursor: pointer;
}

.add-reference {
   &-textfield-append {
      margin-top: -7px;
   }
}
</style>
