import { render, staticRenderFns } from "./chat-window.vue?vue&type=template&id=8dd31816&scoped=true"
import script from "./chat-window.vue?vue&type=script&lang=ts"
export * from "./chat-window.vue?vue&type=script&lang=ts"
import style0 from "./chat-window.vue?vue&type=style&index=0&id=8dd31816&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dd31816",
  null
  
)

export default component.exports