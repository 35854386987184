import { ContentBrickFieldType, ValidationRegex, FormatRegex } from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

class RegularExpressionUtils {
   public readonly regexNotMatchMessage: string = "Value does not match the pattern.";
   readonly oneRowPattern = /\r?\n/g;
   public readonly supportedFlags = ["i", "m", "s", "g"]; // Sequence of supported flag used as parameter for regex
   public readonly minRoundCount: number = 0; // The lowest possible count of decimal places that can be used for rounding using regularExpression
   public readonly maxRoundCount: number = 10; // The highest possible count of decimal places that can be used for rounding using regularExpression

   // Returns true if regular expression is enabled for specific field type, otherwise return False
   public isRegularExpressionEnabled(type: ContentBrickFieldType): boolean {
      if (type !== undefined && type !== null) {
         return (
            type === ContentBrickFieldType.TextBox ||
            type === ContentBrickFieldType.Integer ||
            type === ContentBrickFieldType.Decimal ||
            type === ContentBrickFieldType.Date ||
            type === ContentBrickFieldType.DateTime ||
            type === ContentBrickFieldType.Time ||
            type === ContentBrickFieldType.Calculated
         );
      } else {
         return false;
      }
   }

   // ------------ Regex Validation ------------
   // Validates source value using RegularExpression created by regex and flags properties
   public validate(value: string | undefined, regex: string | undefined, flags: string | undefined = ""): boolean {
      if (!value || value.toString().length == 0) return false;
      if (!this.validateRegexAsString(regex)) return false;

      if (typeof this.validateFlags(flags) === "string") {
         this.notifyErrorMessage(String(this.validateFlags(flags)));
         return false;
      }

      const regexp = flags && flags.length > 0 ? new RegExp(regex, flags) : new RegExp(regex);

      const result = regexp.exec(value.toString()) ? true : false;
      return result;
   }

   // Validates flags characters. If flags contains unsupported flag, returns unsupported flag. Otherway returns True.
   public validateFlags(flags: string | undefined): boolean | string {
      if (flags?.length) {
         for (let i = 0; i < flags.length; i++) {
            if (!this.supportedFlags.includes(flags[i])) {
               return flags[i];
            }
         }
      }
      return true;
   }

   // Formats source value using RegularExpression.
   // First the incoming value is validated using validationRegex.Then the value is formatted using formatRegexes.
   public formatByRegexProperties(
      value: string | undefined,
      validationRegex: ValidationRegex | undefined,
      formatRegexes: FormatRegex[] | undefined
   ): string | undefined {
      if (this.validate(value, validationRegex?.regex, validationRegex?.flags) && formatRegexes?.length) {
         let formattedValue: string | undefined = value ? value.toString() : undefined;

         formatRegexes.forEach((formatRegex) => {
            if (formatRegex && this.validateRegexAsString(formatRegex.regex) && formattedValue?.length) {
               const formatRegexInstance = formatRegex.flags?.length
                  ? new RegExp(formatRegex.regex, formatRegex.flags)
                  : new RegExp(formatRegex.regex);

               if (formatRegexInstance) {
                  if (formatRegex.formatAsNumber) {
                     formattedValue = formattedValue.replace(formatRegexInstance, (match) => {
                        return this.formatAsNumber(match, formatRegex) ?? "";
                     });
                  } else {
                     formattedValue = formattedValue
                        .toString()
                        .replace(formatRegexInstance, formatRegex.formatPattern ?? "");
                  }
               } else {
                  this.notifyErrorMessage(
                     `Format value by Regular Expression failed. FormatRegex regex: '${formatRegex.regex}', flags: '${formatRegex.flags}', formatPattern: '${formatRegex.formatPattern}' compilation error.`
                  );
                  return undefined;
               }
            }
         });

         if (formattedValue?.length) {
            return formattedValue;
         } else {
            return undefined;
         }
      } else {
         return undefined;
      }
   }

   private validateRegexAsString(regex: string | undefined): boolean {
      if (!regex || regex.length == 0) {
         this.notifyErrorMessage("validation by regular expression failed. Unknown regex query");
         return false;
      }
      return true;
   }

   // Formats value as number to local number format by current localization or rounds number to desired decimal places. Expected incoming value is number.
   private formatAsNumber(value: string | undefined, formatRegex: FormatRegex | undefined): string | undefined {
      if (formatRegex?.formatAsNumber && value?.length && !Number.isNaN(Number(value))) {
         let formattedValue: string | undefined = value ? value.toString() : undefined;

         if (formattedValue?.length) {
            if (formatRegex.formatToLocalNumber) {
               if (formatRegex.round !== undefined && formatRegex.round !== null) {
                  // Formates number by current localization. Round integer or decimal number by round value.
                  formattedValue = Number(formattedValue).toLocaleString(undefined, {
                     maximumFractionDigits: formatRegex.round,
                     minimumFractionDigits: formatRegex.round,
                  });
               } else {
                  // Formates number by current localization. Integer formates without round, decimal number is formatted and rounded with maximum decimal places.
                  formattedValue = Number(formattedValue).toLocaleString(undefined, {
                     maximumFractionDigits: 20,
                  });
               }
            } else if (formatRegex.round !== undefined && formatRegex.round !== null) {
               formattedValue = Number(formattedValue).toFixed(formatRegex.round);
            }
         }
         return formattedValue;
      } else {
         return undefined;
      }
   }

   private notifyErrorMessage(message: string) {
      console.log(message);
      EventBus.$emit(Events.DisplayToast, {
         color: "error",
         text: message,
      });
   }

   // When value contains more than one row, it will merge them into one. Otherwise returns value without changes.
   public formatToSingleLine(value: string): string {
      if (!value) return "";

      const lines = value.match(this.oneRowPattern);
      return lines && lines.length > 0 ? value.split(this.oneRowPattern).join("") : value;
   }

   // Default validation regex ensures that the validated value contains only positive or negative integer or decimal number.
   public readonly defaultNumberValidationRegex: ValidationRegex = new ValidationRegex({
      regex: "^\\-?\\d+(\\.\\d+)?$",
      flags: "",
   });

   // Default format regex for positive or negative integer or decimal value formatting.
   // Formats number by current localization and round it
   public readonly defaultNumberFormatRegex: FormatRegex = new FormatRegex({
      regex: "^(\\-?\\d+(\\.\\d+)?)$",
      flags: "",
      formatPattern: "$1",
      formatAsNumber: true,
      formatToLocalNumber: true,
      round: undefined,
   });
}

export default new RegularExpressionUtils();
