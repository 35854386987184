<template>
   <div>
      <v-container fluid class="pl-5 pt-5">
         <v-card-title>Administration</v-card-title>
         <v-row class="justify-center ma-5">
            <v-col v-for="item in administration" :key="item.title" xs="12" sm="12" cols="12" md="4">
               <v-btn
                  tile
                  block
                  x-large
                  class="elevation-3 settings mobile-administration auto-height"
                  color="white"
                  @click="onClickCardTitle(item.url)"
               >
                  <div class="tablet-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <span>{{ item.title }}</span>
                  </div>
                  <div class="mid-tablet-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <div v-html="item.smallTitle"></div>
                  </div>
                  <div class="pc-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <span>{{ item.title }}</span>
                     <v-icon class="ml-2">mdi-chevron-right</v-icon>
                  </div>
                  <div class="mobile-administration-title">
                     <v-icon large>{{ item.icon }}</v-icon>
                  </div>
               </v-btn>
            </v-col>
         </v-row>

         <v-card-title>User Administration</v-card-title>
         <v-row class="justify-center ma-5">
            <v-col v-for="item in accountManagementItems" :key="item.title" xs="12" sm="12" cols="12" md="4">
               <v-btn
                  tile
                  block
                  x-large
                  class="elevation-3 settings mobile-administration auto-height"
                  color="white"
                  @click="onClickCardTitle(item.url)"
               >
                  <div class="tablet-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <span>{{ item.title }}</span>
                  </div>
                  <div class="mid-tablet-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <div v-html="item.smallTitle"></div>
                  </div>
                  <div class="pc-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                     <span>{{ item.title }}</span>
                     <v-icon class="ml-2">mdi-chevron-right</v-icon>
                  </div>
                  <div class="mobile-administration-title">
                     <v-icon large class="mr-2">{{ item.icon }}</v-icon>
                  </div>
               </v-btn>
            </v-col>
         </v-row>
      </v-container>
   </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
   name: "Administration",
   components: {},
})
export default class Administration extends Vue {
   loading: boolean = false;

   administration = [
      {
         title: "Capabilities",
         smallTitle: "Capabilities",
         url: "/capabilities/overview",
         icon: "mdi-certificate",
         color: "red darken-2",
         tooltip: "Capabilities Overview",
      },
      {
         title: "Domains",
         smallTitle: "Domains",
         url: "/domains",
         icon: "mdi-account-group",
         color: "red darken-2",
         tooltip: "Domains",
      },
      {
         title: "Lists",
         smallTitle: "Lists",
         url: "/list/overview",
         icon: "mdi-format-list-bulleted",
         color: "red darken-2",
         tooltip: "Lists Overview",
      },
      {
         title: "Public Holidays",
         smallTitle: "<p>Public</p><p>Holidays</p>",
         url: "/publicholidays/overview",
         icon: "mdi-map-clock",
         color: "red darken-2",
         tooltip: "Public Holidays",
      },
      {
         title: "Qualifications",
         smallTitle: "<p>Quali -</p><p>fications</p>.",
         url: "/qualifications/overview",
         icon: "mdi-account-box-outline",
         color: "red darken-2",
         tooltip: "Qualification Overview",
      },
      {
         title: "Reports",
         smallTitle: "Reports",
         url: "/report/overview",
         icon: "mdi-chart-bar",
         color: "red darken-2",
         tooltip: "Reports",
      },
      {
         title: "Tags and Search Identifiers",
         smallTitle: "<p>Tags and</p><p>Search Identifiers</p>",
         url: "/tag/overview",
         icon: "mdi-tag",
         color: "red darken-2",
         tooltip: "Tag Administration",
      },
      {
         title: "Translations",
         smallTitle: "Translations",
         url: "/translations/overview",
         icon: "mdi-translate",
         color: "red darken-2",
         tooltip: "Translations",
      },
      {
         title: "Unit Types",
         smallTitle: "Unit Types",
         url: "/unitTypes/overview",
         icon: "mdi-numeric-1-box-multiple-outline",
         color: "red darken-2",
         tooltip: "Unit Type Administration",
      },
      {
         title: "Units",
         smallTitle: "Units",
         url: "/units/overview",
         icon: "mdi-weight-kilogram",
         color: "red darken-2",
         tooltip: "Unit Administration",
      },
      {
         title: "Usage Types",
         smallTitle: "<p>Usage</p><p>Types</p>",
         url: "/usageTypes/overview",
         icon: "mdi-blur",
         color: "red darken-2",
         tooltip: "Usage Types Administration",
      },
   ];

   accountManagementItems = [
      {
         title: "Groups",
         smallTitle: "Groups",
         url: "/user/groups",
         icon: "mdi-account-supervisor-circle",
         color: "red darken-2",
         tooltip: "User Groups",
      },
      {
         title: "Organigram",
         smallTitle: "Organigram",
         url: "/user/orgChart",
         icon: "mdi-graph",
         color: "red darken-2",
         tooltip: "Organigram",
      },
      {
         title: "Roles",
         smallTitle: "Roles",
         url: "/user/roles",
         icon: "mdi-account-tie",
         color: "red darken-2",
         tooltip: "User Roles",
      },
      {
         title: "Users",
         smallTitle: "Users",
         url: "/user/overview",
         icon: "mdi-account-supervisor",
         color: "red darken-2",
         tooltip: "Users",
      },
   ];

   onClickCardTitle(itemUrl: string) {
      this.$router.push({ path: `${itemUrl}` });
   }
}
</script>

<style scoped>
.auto-height {
   height: auto !important;
   padding-top: 0.5em !important;
   padding-bottom: 0.5em !important;
}
</style>
