<template>
   <v-card>
      <v-card-title primary-title>
         <v-row>
            <v-col cols="12" md="6">
               {{ translateKey("cbReferencesTab.contentBrickReferencesLabel", translations) }}
            </v-col>
            <v-col cols="12" md="6">
               <v-text-field
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  :label="translateKey('label.search', translations)"
                  single-line
                  color="red darken-2"
                  hide-details
                  autofocus
                  @input="searchDebounced()"
               />
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-data-table
            v-sortable-table="{ onEnd: updateHeaderOrder }"
            :mobile-breakpoint="0"
            class="overview-table pmtool-table"
            dense
            :headers="headerContentBricks"
            :items="contentBricks"
            :loading="loading"
            :search="searchQuery"
            :custom-filter="customSearch"
            :server-items-length="pagingTotalDocuments"
            :options.sync="dataTableOptions"
            :footer-props="footerProps"
         >
            <template #item.version="{ item }">
               <router-link :to="getContentBrickDetailRoute(item)" class="black--text">
                  <div class="max-height">
                     <span>v{{ ("000" + item.version.major).slice(-4) }}</span>
                  </div>
               </router-link>
            </template>
            <template #item.type="{ item }">
               <router-link :to="getContentBrickDetailRoute(item)" class="black--text">
                  <div class="max-height">
                     <span>{{ getContentBrickTypeLabel(item) }}</span>
                  </div>
               </router-link>
            </template>
            <template #item.code="{ item }">
               <router-link :to="getContentBrickDetailRoute(item)" class="black--text">
                  <div class="max-height">
                     <span>{{ item.code }}</span>
                  </div>
               </router-link>
            </template>
            <template #item.displayText="{ item }">
               <router-link :to="getContentBrickDetailRoute(item)" class="black--text">
                  <div class="max-height">
                     <span>{{ item.displayText }}</span>
                  </div>
               </router-link>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseResponse from "@models/BaseResponse";
import {
   SubContentBrickDefinitionApi,
   ISubContentBrickDefinition,
   IContentBrickDefinitionReference,
   QueryOptionsOfContentBrickDefinitionFilterOptions,
   ContentBrickDefinitionFilterOptions,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import { debounce } from "lodash";
import { DataOptions } from "vuetify";

@Component({
   name: "SubContentBrickDetailTabCbUsage",
   components: {},
})
export default class SubContentBrickDetailTabCbUsage extends OverviewBase {
   loading: boolean = true;
   contentBricks: IContentBrickDefinitionReference[] = [];
   searchQuery: string = "";
   filter: ContentBrickDefinitionFilterOptions = ContentBrickDefinitionFilterOptions.fromJS({ type: null });

   @Prop({ required: true })
   subContentBrick: ISubContentBrickDefinition;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   //------------------- Base --------------------
   loadData = this.loadSubContentBrickDefinitionCbReferences;
   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: ["code"],
      sortDesc: [false],
   };

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.headerContentBricks, this.translations);
   }

   mounted() {
      this.loadData();
      this.translateHeaders(this.headerContentBricks, this.translations);
   }

   // ---- table -----
   headerContentBricks = [
      { text: "Code", value: "code", translationKey: "cbReferencesTab.codeHeader" },
      { text: "Name", value: "displayText", translationKey: "cbReferencesTab.nameHeader" },
      { text: "Type", value: "type", sortable: false, translationKey: "cbReferencesTab.typeHeader" },
      { text: "Version", value: "version", translationKey: "cbReferencesTab.versionHeader" },
   ];

   getContentBrickTypeLabel(contentBrickReference: IContentBrickDefinitionReference) {
      return this.translateKey(
         ContentBrickTypeDecorator.get(contentBrickReference.type).translationKey,
         this.translations
      );
   }

   getContentBrickDetailRoute(contentBrickReference: IContentBrickDefinitionReference): any {
      return {
         name: ContentBrickTypeDecorator.get(contentBrickReference.type).detailRouteName,
         params: {
            id: contentBrickReference.id,
         },
      };
   }

   private searchDebounced = debounce(() => this.search(), 200);

   search() {
      if (this.searchQuery.length >= 3) {
         this.filter.searchQuery = this.searchQuery;
         this.loadSubContentBrickDefinitionCbReferences();
      } else if (this.filter.searchQuery && !this.searchQuery) {
         this.filter.searchQuery = undefined;
         this.loadSubContentBrickDefinitionCbReferences();
      }
   }

   // ---- API -----
   async loadSubContentBrickDefinitionCbReferences(): Promise<void> {
      this.loading = true;
      try {
         // prepare api call parameters
         let queryOptions = new QueryOptionsOfContentBrickDefinitionFilterOptions();
         queryOptions.filter = this.filter;
         this.OnBeforePagedQuery(queryOptions);

         // Call the API
         let result = await SubContentBrickDefinitionApi.queryContentBricksUsage(this.subContentBrick.id, queryOptions);
         // Process/Save data etc.
         this.contentBricks = result.documents;

         // preserve pagination parameters
         this.OnAfterPagedQuery(result.continuationToken, result.totalDocumentsCount);
      } catch (error) {
         this.notifyError(error, "query", "ContentBrick references");
      }
      this.loading = false;
   }
}
</script>
