<template>
   <v-text-field
      v-model="valueInternal"
      color="error"
      :label="label"
      :rules="identifierRules"
      counter
      maxlength="80"
      :readonly="readonly"
      :append-icon="icon"
      @click:append="regenerateIdentifier(true)"
   ></v-text-field>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ValidationRules, { ValidationRule } from "@models/shared/ValidationRules";
import DataModelUtils from "@utils/DataModelUtils";
import EventBus from "@backend/EventBus";
import Events, { PointToPointEventData } from "@models/shared/Events";

@Component({
   name: "IdentifierField",
   components: {},
})
export default class IdentifierField extends Vue {
   @Prop({ required: true })
   value: string | null | undefined;

   @Prop({ required: true })
   source: string | undefined;

   @Prop({ required: true })
   label: string;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   eventId: string;

   @Prop({ default: () => [] })
   rules: ValidationRule[];

   get identifierRules(): ValidationRule[] {
      return [...this.rules, ...ValidationRules.identifier];
   }

   get valueInternal(): string | null | undefined {
      return this.value;
   }

   set valueInternal(value: string | null | undefined) {
      this.$emit("input", value);
   }

   get icon(): string | null {
      return this.readonly ? null : "mdi-refresh";
   }

   mounted() {
      EventBus.$on(Events.P2P, this.onPeerToPeer);
   }

   unmounted() {
      EventBus.$off(Events.P2P, this.onPeerToPeer);
   }

   onPeerToPeer(data: PointToPointEventData<void>) {
      if (data.eId != this.eventId) return;

      this.regenerateIdentifier();
   }

   regenerateIdentifier(regenerate: boolean = false) {
      if (this.readonly || !this.source || (!regenerate && this.value)) return;

      let generatedIdentifier = DataModelUtils.generateNodeIdentifier(this.source);

      this.$emit("input", generatedIdentifier);
   }
}
</script>
