<template></template>
<script lang="ts">
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { Component } from "vue-property-decorator";

@Component({
   name: "DetailBase",
})
export default class DetailBase extends ComponentBase {}
</script>
