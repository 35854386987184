import {
   RegularExpressionApi,
   RegularExpression,
   QueryOptionsOfRegularExpressionFilterOptions,
   RegularExpressionFilterOptions,
   EntityStatus,
   FormattingType,
   ItemReference,
} from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";
import { cloneDeep } from "lodash";

class RegularExpressionCachedApi extends CachedApiBase {
   invalidateAll() {
      this.invalidateItem("RegularExpressionApi.getAllRegularExpressions");
   }

   /**
    * Returns all RegularExpressions from cache storage
    * @return List of all RegularExpressions from cache storage
    */
   async getAllRegularExpressions(): Promise<RegularExpression[]> {
      const queryOptions = new QueryOptionsOfRegularExpressionFilterOptions();
      queryOptions.pageSize = -1; // all documents
      queryOptions.filter = new RegularExpressionFilterOptions();

      return await this.cacheEndpointAsync<RegularExpression>(
         "RegularExpressionApi.getAllRegularExpressions",
         RegularExpressionApi.getRegularExpressions.bind(RegularExpressionApi, queryOptions),
         RegularExpression.fromJS
      );
   }

   /**
    * Gets filtered RegularExpressions from all RegularExpression cache
    * @param regularExpressionIds if set, filter by RegularExpression Ids
    * @returns list of all matching RegularExpressions
    */
   async getRegularExpressions(regularExpressionIds: string[] | undefined): Promise<RegularExpression[]> {
      const regularExpressions = await this.getAllRegularExpressions();

      const filteredRegularExpressions = regularExpressions.filter(
         (r) => regularExpressionIds === undefined || regularExpressionIds.find((x) => x === r.id)
      );

      return filteredRegularExpressions;
   }

   /**
    * Gets filtered RegularExpression references from all RegularExpression cache
    * @param status if set, filter by RegularExpression entity status
    * @param formattingType if set, filter by RegularExpression formatting type
    * @returns list of all matching RegularExpression references
    */
   async getRegularExpressionReferences(
      regularExpressionIds: string[] | undefined = undefined,
      status: EntityStatus | undefined = undefined,
      formattingType: FormattingType | undefined = undefined
   ): Promise<ItemReference[]> {
      const regularExpressions = await this.getAllRegularExpressions();

      const filteredRegularExpressions = regularExpressions.filter(
         (r) =>
            (status === undefined || r.entityStatus === status) &&
            (formattingType === undefined || r.formattingType === formattingType) &&
            (regularExpressionIds === undefined || regularExpressionIds.find((x) => x === r.id))
      );

      return filteredRegularExpressions.map(this.regularExpressionToReference);
   }

   private regularExpressionToReference(regularExpression: RegularExpression): ItemReference {
      return new ItemReference({
         id: regularExpression.id,
         code: regularExpression.code,
         displayText: regularExpression.name,
         entityStatus: regularExpression.entityStatus,
         lastModified: regularExpression.lastModified ? cloneDeep(regularExpression.lastModified) : undefined,
      });
   }

   private static _instance: RegularExpressionCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): RegularExpressionCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default RegularExpressionCachedApi.instance;
